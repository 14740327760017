
<div class="hero">

    <div class="hero-content">
        <h1>{{'FAQ' | translate}}</h1>
    </div>

    
<div class="faq-wrapper">
    <div class="container">

        <div class="faq">
            
            <div class="input-wrapper">
                <input type="text" placeholder="Search a keyword" (keyup)="onKey()" [(ngModel)]="input">
                <img src="assets/images/loop.svg" alt="">
            </div>

            <div class="tabordion">
                <section id="section1" #sectionOne class="display">
                    <label id="option1" (click)="showtab('one')"><span [ngClass]="{rotate : firstTabState=='open'}">+</span>
                        <p>{{'What is ClickReview’s review collection stand device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=firstTabState>
                        <article>
                            <p>{{'ClickReview’s NFC-based review collection device is a tool that allows businesses to easily collect customer reviews by placing the device in a prominent location at their business and inviting customers to tap their NFC-enabled phone to leave a review.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section2" #sectionTwo class="display">
                    <label id="option2" (click)="showtab('two')"><span
                            [ngClass]="{rotate : secondTabState=='open'}">+</span>
                            <p>{{'What is ClickReview’s mobile device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=secondTabState>
                        <article>
                            <p>{{'ClickReview’s mobile device is a tool that allows employees to easily collect customer reviews while they work. It is a lightweight, portable device that can be carried by employees throughout their shift.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section3" #sectionThree class="display">
                    <label id="option3" (click)="showtab('three')"><span
                            [ngClass]="{rotate : thirdTabState=='open'}">+</span>
                            <p>{{'How does the device work?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=thirdTabState>
                        <article>
                            <p>{{'The device works by using NFC technology to provide customers with a convenient landing page where they can leave a review on the review platform of their choice.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section4" #sectionFour class="display">
                    <label id="option4" (click)="showtab('four')"><span
                            [ngClass]="{rotate : fourthTabState=='open'}">+</span>
                            <p>{{'What devices are compatible with ClickReview’s device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=fourthTabState>
                        <article>
                            <p>{{'ClickReview’s device is compatible with any smartphone that has NFC capabilities.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section5" #section5 class="display">
                    <label id="option5" (click)="showtab('five')"><span
                            [ngClass]="{rotate : five=='open'}">+</span>
                            <p>{{'Do I need to download an app to use ClickReview’s device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=five>
                        <article>
                            <p>{{'No, there is no need to download an app to use ClickReview’s device. Customers can simply tap their NFC-enabled phone to the device to leave a review.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section6" #section6 class="display">
                    <label id="option6" (click)="showtab('six')"><span
                            [ngClass]="{rotate : six=='open'}">+</span>
                            <p>{{'Can I customize the landing page that customers see when they tap their phone to the device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=six>
                        <article>
                            <p>{{'Yes, ClickReview allows businesses to customize the landing page that customers see when they tap their phone to the device.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section7" #section7 class="display">
                    <label id="option7" (click)="showtab('seven')"><span
                            [ngClass]="{rotate : seven=='open'}">+</span>
                            <p>{{'How do I access and manage the reviews that are collected using the device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=seven>
                        <article>
                            <p>{{'All reviews collected using the device are automatically synced to your ClickReview review management system, where you can monitor and respond to customer reviews.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section8" #section8 class="display">
                    <label id="option8" (click)="showtab('eight')"><span
                            [ngClass]="{rotate : eight=='open'}">+</span>
                            <p>{{'How does ClickReview’s review management system help me manage my online reputation?'}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=eight>
                        <article>
                            <p>{{'ClickReview’s review management system provides businesses with a comprehensive and user-friendly platform for monitoring and managing reviews from multiple platforms, including Google, Yelp, TripAdvisor and many more.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section9" #section9 class="display">
                    <label id="option9" (click)="showtab('nine')"><span
                            [ngClass]="{rotate : nine=='open'}">+</span>
                            <p>{{'Can I monitor my reviews and manage my account from any device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=nine>
                        <article>
                            <p>{{'Yes, ClickReview’s review management system is cloud-based and accessible from any device with a web browser.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section10" #section10 class="display">
                    <label id="option10" (click)="showtab('ten')"><span
                            [ngClass]="{rotate : ten=='open'}">+</span>
                            <p>{{'What happens if a customer leaves a negative review?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=ten>
                        <article>
                            <p>{{'ClickReview’s review management system allows you to monitor and respond to all customer reviews, including negative reviews. Responding to negative reviews in a professional and courteous manner can help mitigate the impact of the negative review.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section11" #section11 class="display">
                    <label id="option11" (click)="showtab('eleven')"><span
                            [ngClass]="{rotate : eleven=='open'}">+</span>
                            <p>{{'How much does ClickReview’s NFC-based device cost?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=eleven>
                        <article>
                            <p>{{'Pricing for ClickReview’s NFC-based device varies based on the plan you choose. Please visit our Pricing page for more information.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section12" #section12 class="display">
                    <label id="option1" (click)="showtab('new1')"><span [ngClass]="{rotate : new1=='open'}">+</span>
                        <p>{{'How much does your review management system cost?' | translate}} </p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new1>
                        <article>
                            <p>{{'Our pricing varies depending on the plan you choose and the number of devices you need. We offer flexible plans that can be tailored to your business’s specific needs. Contact us for more information and a customized quote.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section13" #section13 class="display">
                    <label id="option13" (click)="showtab('new2')"><span
                            [ngClass]="{rotate : new2=='open'}">+</span>
                            <p>{{'Are there any hidden fees or charges?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new2>
                        <article>
                            <p>{{'No, we believe in transparent pricing, so there are no hidden fees or charges. What you see is what you get with our pricing plans.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section14" #section14 class="display">
                    <label id="option14" (click)="showtab('new3')"><span
                            [ngClass]="{rotate : new3=='open'}">+</span>
                            <p>{{'Can I change my plan or cancel at any time?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new3>
                        <article>
                            <p>{{'Yes, you can change your plan or cancel at any time. We offer flexible plans that can be tailored to your changing needs.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section15" #section15 class="display">
                    <label id="option15" (click)="showtab('new4')"><span
                            [ngClass]="{rotate : new4=='open'}">+</span>
                            <p>{{'Is there a setup fee?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new4>
                        <article>
                            <p>{{'No, we do not charge a setup fee for our review management system.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section16" #section16 class="display">
                    <label id="option16" (click)="showtab('new5')"><span
                            [ngClass]="{rotate : new5=='open'}">+</span>
                            <p>{{'How does your pricing compare to other review management systems?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new5>
                        <article>
                            <p>{{'Our pricing is competitive and affordable, and we offer flexible plans that can be tailored to your business’s specific needs. Contact us for a customized quote and to learn more about how we compare to other review management systems.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section17" #section17 class="display">
                    <label id="option17" (click)="showtab('new6')"><span
                            [ngClass]="{rotate : new6=='open'}">+</span>
                            <p>{{'Do you offer any discounts for annual contracts?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new6>
                        <article>
                            <p>{{'Yes, we offer discounts for annual contracts. Contact us for more information and a customized quote.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section18" #section18 class="display">
                    <label id="option18" (click)="showtab('new7')"><span
                            [ngClass]="{rotate : new7=='open'}">+</span>
                            <p>{{'Do you charge for additional features or services?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new7>
                        <article>
                            <p>{{'We offer a range of features and services as part of our pricing plans, but if you require additional services or customizations, there may be additional charges. Contact us for more information.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section19" #section19 class="display">
                    <label id="option19" (click)="showtab('new8')"><span
                            [ngClass]="{rotate : new8=='open'}">+</span>
                            <p>{{'What happens if I exceed the number of devices in my plan?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new8>
                        <article>
                            <p>{{'If you exceed the number of devices in your plan, you may need to upgrade to a higher plan. Contact us for more information and a customized quote.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section20" #section20 class="display">
                    <label id="option20" (click)="showtab('new9')"><span
                            [ngClass]="{rotate : new9=='open'}">+</span>
                            <p>{{'What payment methods do you accept?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=new9>
                        <article>
                            <p>{{'We accept a variety of payment methods, including credit card, PayPal, and bank transfer. Contact us for more information on payment options.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
</div>
