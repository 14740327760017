
<div class="hero">

    <div class="hero-content">
        <h1>{{'Terms and Conditions' | translate}}</h1>
    </div>

</div>

<div class="container">
    <div class="main-wrapper">
        <div class="sidebar">
            <p class="selected" (click)="scroll(one)">1. Introduction</p>
            <p (click)="scroll(two)">2. Definitions</p>
            <p (click)="scroll(three)">3. Service Access and Availability</p>
            <p (click)="scroll(four)">4. Use Restrictions; Updates & Support</p>
            <p class="padding" (click)="scroll(four1)">4.1 Use Restrictions</p>
            <p (click)="scroll(four2)">4.2 Updates and Support</p>
            <p (click)="scroll(five)">5. Beta Services</p>
            <p (click)="scroll(six)">6. Paid Service Terms</p>
            <p (click)="scroll(six1)" class="padding">6.1 Fees</p>
            <p (click)="scroll(six2)" class="padding">6.2 Payments & Invoicing</p>
            <p (click)="scroll(six3)" class="padding">6.3 Payment by Credit Card</p>
            <p (click)="scroll(six4)" class="padding">6.4 Payment Disputes</p>
            <p (click)="scroll(six5)" class="padding">6.5 Suspension or Cancellation of Service; Payment Acceleration</p>
            <p (click)="scroll(six6)" class="padding">6.6 Taxes</p>
            <p (click)="scroll(six7)" class="padding">6.7 Withholding Taxes</p>
            <p (click)="scroll(six8)" class="padding">6.8 Overdue Charges</p>
            <p (click)="scroll(six9)" class="padding">6.9 Term for Paid Services</p>
            <p (click)="scroll(six10)" class="padding">6.10 Termination by ClickReview</p>
            <p (click)="scroll(seven)">7. Termination</p>
            <p (click)="scroll(eight)">8. Survival</p>
            <p (click)="scroll(nine)">9. Privacy Policy & Data Rights</p>
            <p (click)="scroll(nine1)" class="padding">9.1 Privacy Policy</p>
            <p (click)="scroll(nine2)" class="padding">9.2 Data Analytics</p>
            <p (click)="scroll(nine3)" class="padding">9.3 Data Security</p>
            <p (click)="scroll(nine4)" class="padding">9.4 Compliance with Applicable Law</p>
            <p (click)="scroll(nine5)" class="padding">9.5 CCPA</p>
            <p (click)="scroll(ten)">10. Restricted Areas of the Services</p>
            <p (click)="scroll(ten1)" class="padding">10.1 Registration Information</p>
            <p (click)="scroll(ten2)" class="padding">10.2 Access Credentials</p>
            <p (click)="scroll(ten3)" class="padding">10.3 Responsibility</p>
            <p (click)="scroll(eleven)">11. Links and third-party content</p>
            <p (click)="scroll(twelve)">12. Trademarks</p>
            <p (click)="scroll(thirteen)">13. Agencies & Partners</p>
            <p (click)="scroll(thirteen1)" class="padding">13.1 Agency Client</p>
            <p (click)="scroll(thirteen2)" class="padding">13.2 Partners</p>
            <p (click)="scroll(fourteen)">14. Confidential Informations</p>
            <p (click)="scroll(fourteen1)" class="padding">14.1 Definition</p>
            <p (click)="scroll(fourteen2)" class="padding">14.2 Use and Disclosure</p>
            <p (click)="scroll(fourteen3)" class="padding">14.3 Exceptions</p>
            <p (click)="scroll(fourteen4)" class="padding">14.4 Return or Destruction of Confidential Information</p>
            <p (click)="scroll(fourteen5)" class="padding">14.5 Remediese</p>
            <p (click)="scroll(fourteen6)" class="padding">14.6 Survival</p>
            <p (click)="scroll(fiftheen)">15. Proprietary rights</p>
            <p (click)="scroll(fiftheen1)" class="padding">15.1 Proprietary rights</p>
            <p (click)="scroll(fiftheen2)" class="padding">15.2 Feedback</p>
            <p (click)="scroll(sixtheen)">16. Warranty</p>
            <p (click)="scroll(sixtheen1)" class="padding">16.1 Warranty</p>
            <p (click)="scroll(sixtheen2)" class="padding">16.2 Disclaimer</p>
            <p (click)="scroll(seventeen)">17. Limitation of Liability</p>
            <p (click)="scroll(seventeen1)" class="padding">17.1 Indirect Liability</p>
            <p (click)="scroll(seventeen2)" class="padding">17.2 Direct Liability</p>
            <p (click)="scroll(eighteen)">18. Indemnity</p>
            <p (click)="scroll(eighteen)" class="padding">18.1 Indemnity</p>
            <p (click)="scroll(eighteen)" class="padding">18.2 Indemnification Procedure</p>
            <p (click)="scroll(nineten)">19. Miscellaneous</p>
            <p (click)="scroll(nineten1)" class="padding">19.1 Governing Law</p>
            <p (click)="scroll(nineten2)" class="padding">19.2 Venue</p>
            <p (click)="scroll(nineten3)" class="padding">19.3 No Waiver; Severability</p>
            <p (click)="scroll(nineten4)" class="padding">19.4 Assignment</p>
            <p (click)="scroll(nineten5)" class="padding">19.5 Entire Agreement</p>
            <p (click)="scroll(nineten6)" class="padding">19.6 Relationship between the Parties</p>
            <p (click)="scroll(nineten7)" class="padding">19.7 Assignment</p>
            <p (click)="scroll(nineten8)" class="padding">19.8 Third Party Beneficiary</p>
            <p (click)="scroll(nineten9)" class="padding">19.9 Notices</p>
            <p (click)="scroll(twenty)">20. Contacting Us</p>
        </div>
        <div class="center">
            <h2 #one>1. Introduction</h2>
            <p>Welcome to ClickReview’s review management software and devices. These terms and conditions (“Terms”) govern your use of our software, devices, and any related services (collectively, the “Services”). By using our Services, you agree to be bound by these Terms.ClickReview is committed to providing our clients with the best possible review management solutions, while maintaining the highest standards of security, privacy, and ethical business practices. Our Services are designed to help businesses of all sizes improve their online reputation and connect with their customers in a meaningful way.
                <br><br>
                We value our clients and their trust in us, and we take our responsibility to protect their personal and business data seriously. We are committed to complying with all applicable laws and regulations, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA), and we use industry-standard security protocols and encryption to protect the data that we collect.At ClickReview, we believe in transparency and honesty, and we encourage our clients to contact us with any questions or concerns they may have about our Services or their use of our platform. We are dedicated to providing our clients with the tools and support they need to improve their online reputation and succeed in their businesses.
                <br><br>
                We ask that you read these Terms carefully before using our Services. If you do not agree to these Terms, you may not use our Services. By using our Services, you agree to be bound by these Terms and to comply with all applicable laws and regulations.</p>
            <h2 #two>2. Definitions</h2>
            <p>The following terms have the following meanings in these Terms:
                “ClickReview” or “we” or “us” or “our” refers to ClickReview, Inc., a 
                Delaware corporation. <br>
                “Client” refers to any individual or entity that uses our Services.<br>
                “Content” refers to any text, images, videos, or other materials that are 
                part of the Services.<br>
                “Device” refers to our review collection device that uses NFC technology 
                to allow customers to easily leave reviews.<br>
                “Employee” refers to any individual who is employed by a Client and uses 
                the Services.<br>
                “Services” refers to our review management software, devices, and any 
                related services.<br>
                “Terms” refers to these terms and conditions.<br>
                “User” refers to any individual who uses the Services, including but not 
                limited to Clients and Employees.<br>
                “User Content” refers to any content that is submitted to our Services by 
                Users, including reviews, comments, and other materials. </p>
            <h2 #three>3. Service Access and Availability</h2>
            <p>We make every effort to ensure that our Services are available and 
                accessible at all times. However, we cannot guarantee that our Services 
                will always be available or that they will be free from interruptions, errors, 
                or other technical difficulties. <br><br>
                We may need to temporarily suspend or limit access to our Services for 
                various reasons, including but not limited to system maintenance, 
                upgrades, or other technical issues. We will make every effort to provide 
                advance notice of any scheduled maintenance or downtime that may 
                affect the availability of our Services. <br><br>
                We reserve the right to modify, update, or discontinue our Services at any 
                time, without prior notice. We may also impose limits on certain features 
                or functions of our Services, or restrict access to our Services for any 
                reason. <br><br>
                In the event of any interruption or unavailability of our Services, we will 
                make every effort to restore access to our Services as soon as reasonably 
                possible. We are not liable for any loss or damage that may result from the
                unavailability or interruption of our Services, or for any errors or omissions
                in the Content or other materials on our Services.</p>
            <h2 #four>4. Use Restrictions; Updates & Support</h2>
            <h4 #four1>4.1 Use Restrictions</h4>
            <p>
                You may use our Services only in accordance with these Terms and all 
                applicable laws and regulations. You may not use our Services for any 
                unlawful or fraudulent purpose or in any way that could damage, disable, 
                overburden, or impair our Services or any part of them.
                You may not engage in any conduct that could interfere with or disrupt our
                Services, including but not limited to:
                Attempting to gain unauthorized access to our Services or any part of 
                them. <br><br>
                Using any automated means to access or collect data from our Services;
                Posting or transmitting any material that is unlawful, obscene, defamatory,
                or otherwise objectionable.
                Impersonating any person or entity, or misrepresenting your affiliation 
                with any person or entity. <br><br>
                You are responsible for maintaining the confidentiality of your account 
                information, including your login credentials and any other sensitive 
                information related to your use of our Services. You agree to notify us 
                immediately if you become aware of any unauthorized use of your account
                or any other breach of security related to our Services. <br><br>
                We reserve the right to investigate any suspected violations of these 
                Terms or any other misuse of our Services, and to take any action that we 
                deem appropriate, including but not limited to terminating or suspending 
                your access to our Services.
            </p>
            <h4 #four2>4.2 Updates and Support</h4>
            <p>
                We may provide updates or modifications to our Services from time to 
                time, including but not limited to bug fixes, new features, and 
                enhancements. We reserve the right to modify or discontinue any part of 
                our Services at any time without prior notice.<br><br>
                We may also provide technical support or assistance related to the use of 
                our Services. Such support may be provided via email or other means as 
                determined by us. We will make every effort to respond to any support 
                inquiries or issues in a timely manner, but we do not guarantee that we will be able to resolve all issues or provide a solution to every problem.
                We are not responsible for any damage or loss that may result from the 
                use of our Services, including but not limited to any damage to your 
                computer or mobile device, or any loss of data or other information. You 
                are responsible for backing up any data or other materials that you use or 
                store on our Services.<br><br>
                We do not guarantee that our Services will be compatible with all devices 
                or operating systems, or that they will be available in all geographic 
                locations. You are solely responsible for ensuring that your device and 
                software are compatible with our Services, and for complying with any 
                applicable laws or regulations related to the use of our Services.
            </p>
            <h2 #five>5. Beta Services</h2>
            <p>
                From time to time, we may offer access to beta versions of our Services or 
                new features that are still in development (“Beta Services”). Beta Services
                are provided “as is” and may be subject to additional terms and conditions
                that will be made available to you at the time of access.
                Beta Services may contain bugs, errors, or other defects, and may not 
                function properly. You acknowledge that your use of Beta Services is 
                entirely at your own risk, and that we are not responsible for any loss or 
                damage that may result from your use of Beta Services.
                We may modify or discontinue Beta Services at any time without prior 
                notice. We may also choose to end the beta testing period and make the 
                Beta Services generally available, in which case they will be subject to 
                these Terms and any additional terms and conditions that apply to our 
                Services.<br><br>
                If you choose to participate in beta testing, you may be required to 
                provide feedback or other information related to your use of the Beta 
                Services. We may use such feedback and information for any purpose 
                related to the development or improvement of our Services, and you 
                hereby grant us a perpetual, irrevocable, royalty-free, worldwide license to
                use, copy, modify, distribute, and disclose any feedback or information 
                that you provide to us.
            </p>
            <h2 #six>6. Paid Service Terms</h2>
            <h4 #six1>6.1 Fees</h4>
            <p>
                Certain Services may be provided to you for a fee (“Paid Services”). The 
                fees for Paid Services are set forth on our pricing page or in a separate 
                agreement between you and ClickReview.
                Unless otherwise agreed to in writing, all fees are non-refundable and 
                must be paid in advance of the provision of the Paid Services. You agree to
                pay all fees and charges associated with your use of the Paid Services in a 
                timely manner, and to provide us with accurate and complete billing 
                information.<br><br>
                We reserve the right to modify the fees for Paid Services at any time, with 
                or without notice to you. Any such changes to fees will become effective 
                immediately upon the posting of the updated fees on our pricing page or 
                in a separate agreement between you and ClickReview.
                If you fail to pay any fees or charges when due, we may suspend or 
                terminate your access to the Paid Services. We may also charge interest on any late payments at a rate of 2.5% per month or the highest rate 
                allowed by law, whichever is less. <br><br>
                You are solely responsible for all taxes and fees associated with your use 
                of the Paid Services, including but not limited to sales, use, excise, value-
                added, or similar taxes. You agree to indemnify and hold us harmless from
                any claims, damages, or expenses related to your failure to pay any such 
                taxes or fees. 
            </p>
            <h4 #six2>6.2 Payments & Invoicing</h4>
            <p>
                Payment for Paid Services must be made by a valid credit card or other 
                payment method that we accept. You hereby authorize us to charge your 
                credit card or other payment method for all fees associated with your use 
                of the Paid Services. <br><br>
                Unless otherwise agreed to in writing, all fees are due and payable in 
                advance of the provision of the Paid Services. We may issue invoices to 
                you for the fees associated with your use of the Paid Services, and you 
                agree to pay such invoices in a timely manner.
                If you believe that you have been charged in error or that there is a 
                problem with your invoice or payment, please contact us as soon as 
                possible to resolve the issue. We reserve the right to suspend or terminate
                your access to the Paid Services if we are unable to resolve any payment 
                or invoicing issues.<br><br>
                All payments must be made in U.S. dollars, and you are solely responsible 
                for any foreign transaction fees or currency conversion charges that may 
                be assessed by your bank or credit card provider. We do not guarantee 
                that our pricing or invoicing will be error-free, and we are not responsible 
                for any errors or omissions in our pricing or invoicing.<br><br>
                You agree to keep your billing information accurate and up to date at all 
                times, and to notify us promptly if your payment information changes. We 
                may suspend or terminate your access to the Paid Services if we are 
                unable to process payment using the billing information that you have 
                provided.
            </p>
            <h4 #six3>6.3 Payment by Credit Card</h4>
            <p>
                If you choose to pay for Paid Services by credit card, you represent and 
                warrant that you are authorized to use the credit card that you provide to 
                us, and that all information that you provide to us in connection with your 
                use of the credit card is accurate and complete. <br><br>
                We use third-party payment processors to process credit card payments, 
                and we do not store or retain any credit card information on our own 
                servers or systems. By providing your credit card information to us, you 
                authorize us to share that information with our third-party payment 
                processors for the purpose of processing payments. <br><br>
                You agree to notify us immediately if you become aware of any 
                unauthorized use of your credit card or other payment method. We will 
                use reasonable efforts to assist you in resolving any unauthorized use of 
                your credit card or payment method, but we are not responsible for any 
                unauthorized charges that may be made using your credit card or 
                payment method. <br><br>
                We reserve the right to suspend or terminate your access to the Paid 
                Services if any credit card or payment method that you have provided to us is declined, expires, or is otherwise not valid. We may also charge 
                interest on any outstanding balances at a rate of 2.5% per month or the 
                highest rate allowed by law, whichever is less.
            </p>
            <h4 #six4>6.4 Payment Disputes</h4>
            <p>
                If you have a dispute regarding a payment or invoice related to the Paid 
                Services, please contact us as soon as possible to attempt to resolve the 
                dispute. We will work with you in good faith to resolve the dispute as 
                quickly and fairly as possible. <br><br>
                If you dispute a charge made by ClickReview on your credit card statement
                or other payment method, you must notify us in writing within thirty (30) 
                days of the charge. If you do not dispute the charge within this time 
                period, you waive any right to dispute the charge. <br><br>
                If you dispute a charge made by ClickReview, we reserve the right to 
                suspend or terminate your access to the Paid Services until the dispute is 
                resolved. We may also charge interest on any outstanding balances at a 
                rate of 2.5% per month or the highest rate allowed by law, whichever is 
                less. <br><br>
                If we determine that a disputed charge was made in error, we will issue a 
                credit to your account or refund the disputed amount to your credit card or
                other payment method. If we determine that a disputed charge was made 
                in error by your credit card provider or other payment processor, we will 
                assist you in resolving the dispute but are not responsible for any resulting
                charges or fees.
            </p>
            <h4 #six5>6.5 Suspension or Cancellation of Service; Payment Acceleration</h4>
            <p>
                ClickReview reserves the right to suspend or terminate your access to the 
                Paid Services for any reason, including without limitation for any breach of 
                these Terms of Service or for non-payment of fees. In the event that we 
                suspend or terminate your access to the Paid Services, we will not refund 
                any fees that you have already paid for the current subscription period.
                If your access to the Paid Services is suspended or terminated, you may 
                not be able to access your account data or other content that you have 
                stored using the Paid Services. You are solely responsible for backing up 
                any content or data that you store using the Paid Services, and we are not 
                responsible for any loss or corruption of such content or data. <br><br>
                If we terminate your access to the Paid Services for breach of these Terms 
                of Service, you agree to pay any outstanding fees or charges that you owe
                to us within thirty (30) days of termination. If you fail to pay such fees or 
                charges within this time period, we may accelerate payment of any 
                outstanding amounts, including those that are not yet due or payable, and
                may take legal action to collect such amounts. <br><br>
                We may also suspend or terminate your access to the Paid Services if we 
                believe that you are using the Paid Services in a manner that violates 
                applicable laws, rules, or regulations or that is harmful to our business or 
                reputation. In such cases, we will not refund any fees that you have 
                already paid for the current subscription period.
            </p>
            <h4 #six6>6.6 Taxes</h4>
            <p>
                You are responsible for paying any applicable taxes or fees that are 
                assessed on your use of the Paid Services, including without limitation 
                sales, use, or value-added taxes, as well as any customs duties, tariffs, or 
                other charges that may be imposed by governmental authorities. All fees 
                charged by ClickReview for the Paid Services are exclusive of any such 
                taxes or fees. <br><br>
                If we are required by applicable law to collect any such taxes or fees, we 
                will add them to your invoice or bill and you agree to pay them. If you are 
                exempt from any taxes or fees, you must provide us with valid tax 
                exemption documentation prior to using the Paid Services. If you fail to 
                provide us with such documentation, we may charge you for any taxes or 
                fees that we are required by law to collect. <br><br>
                You agree to indemnify and hold ClickReview harmless from any claims, 
                damages, or other liabilities arising from your failure to pay any taxes or 
                fees that are assessed on your use of the Paid Services.
            </p>
            <h4 #six7>6.7 Withholding Taxes</h4>
            <p>
                If you are located outside of the United States and we are required by 
                applicable law to withhold any taxes or fees from payments that we make 
                to you for your use of the Paid Services, we may do so and remit such 
                taxes or fees to the appropriate taxing authorities.
                You agree to provide us with any documentation or information that we 
                may request to verify your tax status or to assist us in complying with any 
                applicable tax or withholding requirements. If you fail to provide us with 
                such documentation or information, we may suspend or terminate your 
                access to the Paid Services. <br><br>
                You are solely responsible for complying with any applicable tax or 
                withholding requirements in your jurisdiction, and we are not responsible 
                for any taxes, fees, or other charges that may be assessed on your use of 
                the Paid Services.
            </p>
            <h4 #six8>6.8 Overdue Charges</h4>
            <p>
                If you fail to pay any fees or charges that are due for your use of the Paid 
                Services, we may suspend or terminate your access to the Paid Services, 
                in addition to any other remedies that we may have at law or in equity.
                In the event that any fees or charges are not paid when due, we may 
                charge interest on the outstanding balance at the rate of 2.5% per month, 
                or the maximum rate permitted by law, whichever is less. You agree to 
                pay all reasonable costs and expenses that we incur in connection with 
                collecting any amounts that are past due, including without limitation 
                collection agency fees and attorneys’ fees. <br><br>
                We may also require that you provide a valid credit card or other payment 
                method to be kept on file for automatic payment of any fees or charges 
                that are due, and you authorize us to charge such payment method for 
                any amounts that are owed. <br><br>
                You are responsible for keeping your payment information up to date and 
                accurate, and you agree to promptly notify us if your payment information 
                changes or if there are any issues or problems with your payment method.
                We are not responsible for any fees, charges, or other costs that may be 
                assessed by your payment provider.
            </p>
            <h4 #six9>6.9 Term for Paid Services</h4>
            <p>
                The term for your use of the Paid Services will be as specified in your order
                or agreement with ClickReview. Unless otherwise specified, the term will 
                automatically renew for additional periods of the same duration unless 
                either party gives written notice of non-renewal at least 30 days before the
                end of the then-current term. <br><br>
                If you choose to cancel your use of the Paid Services before the end of the 
                then-current term, you will not be entitled to a refund of any fees or 
                charges that you have already paid, and you will be responsible for paying
                all fees and charges that are due through the end of the then-current 
                term. <br><br>
                We may terminate your access to the Paid Services immediately in the 
                event of a material breach of these terms by you, without notice or refund.
                Upon any termination of your access to the Paid Services, you must 
                immediately cease using the Paid Services and any related materials, and 
                you will remain responsible for paying all fees and charges that are due 
                through the effective date of termination.
            </p>
            <h4 #six10>6.10 Termination by ClickReview</h4>
            <p>
                ClickReview may terminate your access to the Paid Services at any time, 
                with or without cause, upon notice to you. If we terminate your access to 
                the Paid Services without cause, we will refund any fees or charges that 
                you have already paid for the unused portion of the then-current term.
                In addition to any other rights or remedies that we may have at law or in 
                equity, we may terminate your access to the Paid Services immediately in 
                the event of a material breach of these terms by you, without notice or 
                refund. Upon any termination of your access to the Paid Services, you 
                must immediately cease using the Paid Services and any related 
                materials, and you will remain responsible for paying all fees and charges 
                that are due through the effective date of termination. <br><br>
                You acknowledge and agree that ClickReview shall not be liable to you or 
                any third party for any termination of your access to the Paid Services, or 
                for any deletion or destruction of any of your data or information that is 
                stored or processed by the Paid Services.
            </p>
            <h2 #seven>7. Termination</h2>
            <p>
                You may stop using our Service at any time and for any reason. We may 
                terminate your use of the Service at any time and for any reason, without 
                notice or liability to you, including if we believe that you have violated or 
                acted inconsistently with the letter or spirit of these Terms. Upon any 
                termination of your use of the Service, you must immediately cease using 
                the Service and any related materials, and you will remain responsible for 
                paying all fees and charges that are due through the effective date of 
                termination. <br><br>
                In addition, we reserve the right to modify, suspend, or discontinue the 
                Service, or any part thereof, at any time and for any reason, with or 
                without notice or liability to you.
                All provisions of these Terms that by their nature should survive 
                termination shall survive termination, including, without limitation, 
                ownership provisions, warranty disclaimers, indemnity, and limitations of 
                liability.
            </p>
            <h2 #eight>8. Survival</h2>
            <p>
                The provisions of these Terms that by their nature should survive 
                termination shall survive any expiration or termination of these Terms, 
                including, without limitation, provisions regarding ownership, disclaimer of 
                warranties, indemnification, limitations of liability, and dispute resolution.
                Any provision of these Terms that is held to be invalid or unenforceable 
                shall be severed and shall not affect the validity or enforceability of the 
                remaining provisions of these Terms. <br><br>
                These Terms, together with any additional terms to which you agree when 
                using particular elements of the Service, constitute the entire and 
                exclusive agreement between you and ClickReview regarding the Service, 
                and these Terms supersede and replace any prior agreements between 
                you and ClickReview regarding the Service. <br><br>
                ClickReview’s failure to enforce any right or provision of these Terms shall 
                not be deemed a waiver of such right or provision, and any waiver of any 
                right or provision of these Terms must be in writing and signed by an 
                authorized representative of ClickReview. <br><br>
                You may not assign or transfer these Terms, by operation of law or 
                otherwise, without ClickReview’s prior written consent. Any attempt by you
                to assign or transfer these Terms, without such consent, will be null and of
                no effect. ClickReview may assign or transfer these Terms, at its sole 
                discretion, without restriction. Subject to the foregoing, these Terms shall 
                bind and inure to the benefit of the parties, their successors, and 
                permitted assigns.
            </p>
            <h2 #nine>9. Privacy Policy & Data Rights</h2>
            <h4 #nine1>9.1 Privacy Policy</h4>
            <p>
                ClickReview respects your privacy and is committed to protecting your 
                personal data. Our Privacy Policy explains how we collect, use, and 
                disclose information about you when you use our Service, and the rights 
                and choices you have with respect to that information. By using our 
                Service, you consent to our collection and use of your information as 
                described in our Privacy Policy. <br><br>
                We may update our Privacy Policy from time to time. If we make material 
                changes to our Privacy Policy, we will provide notice to you, either by 
                posting the updated Privacy Policy on our website or by contacting you 
                directly. Your continued use of the Service after we have updated our 
                Privacy Policy will constitute acceptance of the updated Privacy Policy.
                To learn more about our data practices, please read our Privacy Policy. If 
                you have any questions about our Privacy Policy, please contact us 
                at info@clickreview.us
            </p>
            <h4 #nine2>9.2 Data Analytics</h4>
            <p>
                ClickReview may use data analytics tools to collect and analyze information
                about your use of the Service. This information is used to help us improve 
                the Service and to provide you with a better user experience.<br><br>
                We may share this information with our service providers or partners for 
                the purposes of providing the Service, improving the Service, and 
                developing new products and services. We may also use this information 
                to generate reports and analysis that help us understand how users interact with the Service.
                <br><br>
                Any information that we collect through data analytics tools will be treated
                in accordance with our Privacy Policy. We may use cookies, web beacons, 
                and other similar technologies to collect this information. You can opt-out 
                of data analytics tools by adjusting the settings in your web browser or 
                mobile device.
                Please note that we may also collect and analyze data from your use of 
                the Service to comply with legal obligations or to protect our rights and 
                interests. If you have any questions about our data analytics practices, 
                please contact us at info@clickreview.us
            </p>
            <h4 #nine3>9.3 Data Security</h4>
            <p>
                ClickReview takes reasonable measures to protect your personal data from
                unauthorized access, disclosure, alteration, and destruction. We use 
                industry-standard security measures to protect the confidentiality, 
                integrity, and availability of your information.
                However, no method of transmission over the internet or method of 
                electronic storage is completely secure. Therefore, we cannot guarantee 
                absolute security of your information. In the event of a data breach, we 
                will notify you and the appropriate authorities in accordance with 
                applicable laws. <br><br>
                You are responsible for maintaining the security of your login credentials 
                and account information. You should use a strong, unique password and 
                avoid sharing your password with anyone. You should also notify us 
                immediately if you suspect any unauthorized access to your account or 
                other security breach.
            </p>
            <h4 #nine4>9.4 Compliance with Applicable Law</h4>
            <p>ClickReview is committed to complying with all applicable laws and 
                regulations regarding privacy, data protection, and the use of our Service. 
                We will cooperate with law enforcement authorities and other government 
                officials as required by law or to protect our rights and interests.
                You are responsible for complying with all applicable laws and regulations 
                when using our Service, including laws related to data protection, privacy, 
                and intellectual property. You may not use our Service in any manner that 
                violates any applicable law or infringes on the rights of any third party.
                If you believe that our Service or any user content posted on our Service 
                violates your intellectual property rights or other legal rights, please 
                contact us at info@clickreview.us. We will investigate your complaint and 
                take appropriate action in accordance with our policies and applicable 
                laws.</p>
            <h4 #nine5>9.5 CCPA</h4>
            <p>If you are a resident of California, you have certain rights under the 
                California Consumer Privacy Act (“CCPA”). These rights include the right to
                access, delete, and opt-out of the sale of your personal information.
                ClickReview collects certain personal information from California residents 
                when they use our Service. This personal information may include your 
                name, email address, IP address, and device information.
                You have the right to request access to the personal information that we 
                have collected about you, and to request that we delete this information. You also have the right to opt-out of the sale of your personal information. 
                To exercise these rights, please contact us at info@clickreview.us</p>
            <h2 #ten>10. Restricted Areas of the Services</h2>
            <h4 #ten1>10.1 Registration Information</h4>
            <p>To access certain restricted areas of our Service, you may be required to 
                register for an account and provide certain information, including your 
                name, email address, and password. You must provide accurate, 
                complete, and up-to-date information when registering for an account, and
                you must keep your account information updated.
                You are responsible for maintaining the security of your login credentials 
                and account information. You should use a strong, unique password and 
                avoid sharing your password with anyone. You should also notify us 
                immediately if you suspect any unauthorized access to your account or 
                other security breach.<br><br>
                By registering for an account, you agree to receive administrative and 
                promotional emails from us. You may opt-out of receiving promotional 
                emails at any time by following the instructions in the email.
                We reserve the right to suspend or terminate your account if we suspect 
                that you have provided false or misleading registration information, or if 
                you violate these terms of service.</p>
            <h4 #ten2>10.2 Access Credentials</h4>
            <p>Certain areas of our Service may require you to use access credentials, 
                    such as usernames, passwords, or security tokens, to gain entry. You are 
                    responsible for keeping your access credentials secure and confidential.
                    You must not share your access credentials with anyone, and you must 
                    use reasonable precautions to prevent unauthorized access to your 
                    account. You should also notify us immediately if you suspect any 
                    unauthorized access to your account or other security breach.
                    You may not use the access credentials of another user to gain access to 
                    the Service. You may also not attempt to gain unauthorized access to any 
                    part of the Service, or to any other system or network connected to the 
                    Service.
                    We reserve the right to suspend or terminate your access to the Service if 
                    we suspect that you have provided false or misleading access credentials, 
                    or if you violate these terms of service.</p>
            <h4 #ten3>10.3 Responsibility</h4>
            <p>Access to certain areas of our Service may require you to take on 
                additional responsibility, such as managing your employee accounts, 
                overseeing your online reviews, or monitoring your social media profiles. 
                You are solely responsible for any actions taken under your account or 
                access credentials, and you are responsible for ensuring that your use of 
                the Service complies with all applicable laws and regulations.
                You agree to indemnify and hold ClickReview harmless from and against 
                any and all claims, damages, liabilities, costs, and expenses (including 
                reasonable attorneys’ fees) arising from or related to your use of the 
                Service, your breach of these terms of service, or your violation of any 
                applicable law or regulation. We reserve the right to suspend or terminate your access to the Service if 
                we suspect that you have violated any applicable law or regulation, or if 
                you violate these terms of service.</p>
            <h2 #eleven>11. Links and third-party content</h2>
            <p>Our Service may include links to other websites, applications, or services 
                that are not owned or controlled by ClickReview. We do not endorse, 
                recommend, or assume any responsibility for any third-party content or 
                services, or for the privacy practices or other policies of any third-party 
                websites or services.
                You agree that ClickReview is not responsible for the accuracy, legality, or 
                appropriateness of any third-party content, or for any damage or loss that 
                may result from your use of such content or services. You further agree 
                that ClickReview will not be liable for any direct, indirect, incidental, 
                special, or consequential damages arising from or related to any third-
                party content or services.<br><br>
                You may encounter links to our Service from other websites or services. 
                You agree not to use our trademarks, service marks, or logos in any way 
                that may suggest endorsement, affiliation, or sponsorship by ClickReview, 
                or in any way that is likely to cause confusion or dilution of our brand.
                We reserve the right to remove any links or content from our Service, or to
                disable access to our Service from any third-party website or service, at 
                any time and for any reason.</p>
            <h2 #twelve>12. Trademarks</h2>
            <p>
                ClickReview’s trademarks, service marks, logos, and trade names 
                (collectively, the “ClickReview Marks”) are valuable assets that we take 
                seriously. You acknowledge and agree that all rights, title, and interest in 
                and to the ClickReview Marks are and shall remain the exclusive property 
                of ClickReview.
                You agree not to use any of the ClickReview Marks without our prior written
                consent, and you agree not to use any confusingly similar marks or names
                in connection with any products, services, or materials that are not 
                authorized by ClickReview.
                If you are authorized to use the ClickReview Marks, you must comply with 
                our brand guidelines and usage requirements, as communicated to you 
                from time to time.<br><br>
                You agree not to challenge, oppose, or contest the validity of the 
                ClickReview Marks or the ownership or registration thereof by ClickReview.
                If you become aware of any unauthorized use of the ClickReview Marks, or 
                any use that may infringe our intellectual property rights, please contact 
                us at info@clickreview.us
                ClickReview reserves the right to take any appropriate legal action to 
                protect our intellectual property rights, including seeking injunctive relief 
                and damages against any infringing party.
            </p>
            <h2 #thirteen>13. Agencies & Partners</h2>
            <h4 #thirteen1>13.1 Agency Client</h4>
            <p>If you are an agency or a partner managing accounts on behalf of your 
                clients, you must agree to the following terms and conditions:<br>
                You must have a valid agreement in place with each client that authorizes 
                you to access and use the ClickReview Services on their behalf.<br>
                You are responsible for ensuring that your clients comply with these Terms
                of Service and all applicable laws, regulations, and policies related to the 
                use of the ClickReview Services.<br>
                You are responsible for creating and managing accounts for your clients, 
                and for providing support to your clients with respect to their use of the 
                ClickReview Services.<br>
                You may not share your account credentials or other access information 
                with your clients or with any third party.<br>
                You may not use the ClickReview Services to violate any applicable laws or 
                regulations, or to engage in any fraudulent, deceptive, or malicious 
                conduct.<br>
                You must promptly report to ClickReview any suspicious or unauthorized 
                use of the ClickReview Services by any of your clients or third parties.
                You understand and acknowledge that ClickReview is not responsible for 
                any disputes, claims, or liabilities arising from your relationship with your 
                clients or any third party.<br>
                You agree to indemnify and hold ClickReview harmless from any and all 
                claims, damages, or expenses arising from your use of the ClickReview 
                Services on behalf of your clients.<br>
                ClickReview may terminate your access to the ClickReview Services at any 
                time if we believe that you are in breach of these Terms of Service, or if 
                we determine that it is necessary to protect our interests or the interests 
                of our users or partners.<br>
                If you are an agency or partner, you must comply with any additional 
                terms and conditions that ClickReview may provide to you from time to 
                time.<br>
                By using the ClickReview Services on behalf of your clients, you 
                acknowledge that you have read, understood, and agreed to these terms 
                and conditions, and that you have the authority to bind your clients to 
                these terms and conditions as well.</p>
            <h4 #thirteen2>13.2 Partners</h4>
            <p>If you are a partner of ClickReview, you must agree to the following terms 
                and conditions:
                You must have a valid agreement in place with ClickReview that authorizes
                you to promote, distribute, or resell the ClickReview Services.<br>
                You may not use any false or misleading representations to promote or 
                advertise the ClickReview Services.<br>
                You must comply with all applicable laws, regulations, and policies related 
                to your marketing and promotion of the ClickReview Services.<br>
                You may not modify, rebrand, or create derivative works of the ClickReview
                Services without the prior written consent of ClickReview.<br>
                You may not sublicense, assign, or transfer your rights or obligations 
                under this agreement without the prior written consent of ClickReview.<br>
                You must promptly report to ClickReview any suspicious or unauthorized 
                use of the ClickReview Services by any third party.<br>
                You understand and acknowledge that ClickReview is not responsible for 
                any disputes, claims, or liabilities arising from your relationship with your 
                customers or any third party. <br>
                You agree to indemnify and hold ClickReview harmless from any and all 
                claims, damages, or expenses arising from your promotion or distribution 
                of the ClickReview Services.<br>
                ClickReview may terminate your agreement with us at any time if we 
                believe that you are in breach of these terms and conditions, or if we 
                determine that it is necessary to protect our interests or the interests of 
                our users or partners.<br>
                If you are a partner, you must comply with any additional terms and 
                conditions that ClickReview may provide to you from time to time.
                By promoting, distributing, or reselling the ClickReview Services, you 
                acknowledge that you have read, understood, and agreed to these terms 
                and conditions.
            </p>
            <h2 #fourteen>14. Confidential Information</h2>
            <h4 #fourteen1>14.1 Definition</h4>
            <p>
                “Confidential Information” means any non-public information, whether 
                written, oral, or electronic, that ClickReview designates as confidential, or 
                that, under the circumstances surrounding disclosure, ought to be treated 
                as confidential. Confidential Information may include, without limitation, 
                information related to the Services, our business, operations, employees, 
                financial information, and customers.</p>
            <h4 #fourteen2>
                14.2 Use and Disclosure</h4>
            <p>
                You acknowledge and agree that all Confidential Information is the sole 
                and exclusive property of ClickReview. You agree to keep all Confidential 
                Information in strict confidence, and not to use, disclose, or reproduce it, 
                directly or indirectly, in whole or in part, except as necessary to perform 
                your obligations under these terms of service. You agree to protect the 
                confidentiality of the Confidential Information using at least the same 
                degree of care as you would use to protect your own confidential 
                information.</p>
            <h4 #fourteen3>
                14.3 Exceptions</h4>
            <p>
                The obligations of confidentiality under this section shall not apply to any 
                information that (i) is or becomes generally known to the public through 
                no fault of your own, (ii) was rightfully in your possession prior to receipt 
                from ClickReview, without obligation of confidentiality, (iii) is rightfully 
                obtained by you from a third party without restriction on use or disclosure,
                (iv) is independently developed by you without reference to or use of 
                Confidential Information, or (v) is required to be disclosed by law or legal 
                process, provided that you give ClickReview prior written notice of the 
                required disclosure and cooperates with ClickReview to obtain a protective 
                order or other appropriate relief.</p>
            <h4 #fourteen4>14.4 Return or Destruction of Confidential Information</h4>
            <p>Upon the termination or expiration of these terms of service, or upon the 
                request of ClickReview, you shall promptly return all Confidential 
                Information to ClickReview or certify in writing that all such Confidential 
                Information has been destroyed, and that no copies of such Confidential 
                Information have been retained.</p>
            <h4 #fourteen5>14.5 Remedies</h4>
            <p>You acknowledge that any breach of your obligations under this section 
            may cause ClickReview irreparable harm, for which monetary damages 
            may not be an adequate remedy. Therefore, ClickReview shall be entitled 
            to seek injunctive relief to enforce the provisions of this section, in 
            addition to any other remedies available at law or in equity.</p>
            <h4 #fourteen6>14.6 Survival</h4>
            <p>The provisions of this section shall survive the termination or expiration of 
            these terms of service for any reason.</p>
            <h2 #fiftheen>15. Proprietary rights</h2>
            <h4 #fiftheen1>15.1 Proprietary Rights</h4>
            <p>
                You acknowledge and agree that the Services and any necessary software 
                used in connection with the Services (“Software”) contain proprietary and 
                confidential information that is protected by applicable intellectual 
                property and other laws. You further acknowledge and agree that content 
                contained in sponsor advertisements or information presented to you 
                through the Services or by advertisers is protected by copyrights, 
                trademarks, service marks, patents or other proprietary rights and laws. 
                Except as expressly authorized by ClickReview or advertisers, you agree 
                not to modify, rent, lease, loan, sell, distribute or create derivative works 
                based on the Services or the Software, in whole or in part. <br><br>
                ClickReview grants you a personal, non-transferable and non-exclusive 
                right and license to use the object code of its Software on a single 
                computer; provided that you do not (and do not allow any third party to) 
                copy, modify, create a derivative work of, reverse engineer, reverse 
                assemble or otherwise attempt to discover any source code, sell, assign, 
                sublicense, grant a security interest in or otherwise transfer any right in 
                the Software. You agree not to modify the Software in any manner or form,
                or to use modified versions of the Software, including (without limitation) 
                for the purpose of obtaining unauthorized access to the Services. You 
                agree not to access the Services by any means other than through the 
                interface that is provided by ClickReview for use in accessing the Services.
            </p>
            <h4 #fiftheen2>15.2 Feedback</h4>
            <p>If you provide ClickReview with any suggestions, comments or other 
                feedback (“Feedback”) relating to the Services, you hereby assign to 
                ClickReview all rights in the Feedback and agree that ClickReview shall 
                have the right to use such Feedback and related information in any 
                manner it deems appropriate. ClickReview will treat any Feedback you 
                provide to ClickReview as non-confidential and non-proprietary. You agree 
                that you will not submit to ClickReview any information or ideas that you 
                consider to be confidential or proprietary.</p>
            <h2 #sixtheen>16. Warranty & Disclaimer</h2>
            <h4 #sixtheen1>16.1 Warranty</h4>
            <p>ClickReview warrants that the Services will be provided in a professional 
                manner in accordance with industry standards. If you notify ClickReview in writing of any non-conformance, ClickReview will, at its expense, use 
                reasonable commercial efforts to correct such non-conformance promptly, 
                or provide you with an alternative means of accomplishing the desired 
                performance. The foregoing is your sole and exclusive remedy for any 
                breach of this warranty. <br><br>
                EXCEPT AS OTHERWISE EXPRESSLY PROVIDED HEREIN, THE SERVICES ARE
                PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND ClickReview 
                MAKES NO WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, 
                STATUTORY OR OTHERWISE, AND ClickReview SPECIFICALLY DISCLAIMS 
                ALL IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION ANY 
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR 
                PURPOSE, TITLE OR NON-INFRINGEMENT. ClickReview DOES NOT 
                WARRANT THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, OR 
                THAT THE OPERATION OF THE SERVICES WILL BE UNINTERRUPTED, 
                TIMELY, SECURE, OR ERROR-FREE, OR THAT ERRORS IN THE SERVICES 
                WILL BE CORRECTED. ClickReview DOES NOT WARRANT THAT THE 
                SERVICES WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. 
                YOU UNDERSTAND AND AGREE THAT YOU USE THE SERVICES AT YOUR 
                OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE
                FOR ANY DAMAGES THAT MAY RESULT FROM YOUR USE OF THE SERVICES.
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED 
                WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>
            <h4 #sixtheen2>16.2 Disclaimer</h4>
            <p>To the maximum extent permitted by applicable law, in no event will 
                ClickReview be liable to you or any third party for any indirect, special, 
                incidental, consequential, exemplary or punitive damages of any kind, 
                including but not limited to damages for loss of profits, loss of revenue, 
                loss of data, loss of use or other economic advantage, whether based in 
                contract, tort or otherwise, arising out of or in any way connected with the 
                use or performance of the services, even if ClickReview has been advised 
                of the possibility of such damages. ClickReview’s liability under this 
                agreement will in no event exceed the fees paid by you to ClickReview 
                under this agreement during the twelve-month period immediately 
                preceding the date on which the claim arose. This limitation of liability 
                applies even if the remedies under this agreement fail of their essential 
                purpose.</p>
            <h2 #seventeen>17. Limitation of Liability</h2>
            <h4 #seventeen1>17.1 Indirect Liability</h4>
            <p>To the maximum extent permitted by applicable law, ClickReview’s liability
                for any and all claims, damages, or losses whatsoever arising out of or in 
                connection with this agreement or the services provided hereunder, 
                including without limitation direct, indirect, incidental, special, 
                consequential, punitive or exemplary damages, loss of profits or revenue, 
                loss of use or interruption of business, will be limited to the amount paid 
                by you to ClickReview during the twelve (12) months immediately 
                preceding the event giving rise to the liability, or if no such payments have
                been made, to one hundred dollars ($100.00 USD). This limitation of 
                liability applies regardless of the cause of action, whether in contract, tort, statute, strict liability or other legal or equitable theory. The parties 
                acknowledge and agree that the essential purpose of this provision is to 
                limit the potential liability of ClickReview arising out of this agreement and
                the services provided hereunder, and that the parties would not enter into 
                this agreement without these limitations.</p>
            <h4 #seventeen2>17.2 Direct Liability</h4>
            <p>In no event will ClickReview be liable for any direct damages arising out of 
                or in connection with this agreement or the services provided hereunder, 
                whether based on breach of contract, tort (including negligence), strict 
                liability or any other legal or equitable theory, and whether or not 
                ClickReview has been advised of the possibility of such damages. The total
                liability of ClickReview for any and all claims, damages, or losses 
                whatsoever arising out of or in connection with this agreement or the 
                services provided hereunder, whether in contract, tort, statute, strict 
                liability or other legal or equitable theory, will in no event exceed the 
                amount paid by you to ClickReview during the twelve (12) months 
                immediately preceding the event giving rise to liability.</p>
            <h2 #eighteen>18. Indemnity</h2>
            <h4 #eighteen1>18.1 Indemnity</h4>
            <p>You will defend, indemnify and hold harmless ClickReview 
                and its affiliates, and their respective directors, officers, employees and 
                agents (collectively, the “Indemnified Parties”) from and against any 
                claims, damages, liabilities, costs and expenses (including reasonable 
                attorneys’ fees) arising out of or related to: (i) your use of the Services; (ii)
                any Content submitted, posted or transmitted by you; (iii) your breach of 
                any term or condition of this agreement or any applicable policy or 
                guideline; (iv) any claim that your use of the Services or Content infringes 
                the intellectual property rights or other rights of a third party or violates 
                applicable law; (v) any claim that your use of the Services has harmed a 
                third party; or (vi) any claim related to the sales of your products or 
                services. You will use counsel reasonably satisfactory to ClickReview to 
                defend each indemnified claim. If at any time ClickReview reasonably 
                determines that any indemnified claim might adversely affect ClickReview,
                ClickReview may take control of the defense at its expense. You may not 
                consent to the entry of any judgment or enter into any settlement without 
                the prior written consent of ClickReview, which may not be unreasonably 
                withheld.</p>
            <h4 #eighteen2>18.2 Indemnification Procedure</h4>
            <p>In the event that we receive notice of any claim or action arising from your
                use of our service, you agree to indemnify, defend, and hold harmless 
                ClickReview, our affiliates, and our respective officers, directors, 
                employees, and agents from and against any and all losses, liabilities, 
                damages, costs, and expenses (including reasonable attorneys’ fees and 
                expenses) resulting from or related to such claim or action.
                You agree to promptly notify us of any such claim or action, and we 
                reserve the right, at our sole discretion, to assume the exclusive defense 
                and control of any matter subject to indemnification by you, in which 
                event you will fully cooperate with us in asserting any available defenses.</p>
            <h2 #nineten>19. Miscellaneous</h2>
            <h4 #nineten1>19.1 Governing Law</h4>
            <p>This Agreement shall be governed by and construed in accordance with 
                the laws of the state in which ClickReview’s principal place of business is 
                located, without giving effect to any choice of law or conflict of law 
                provision or rule (whether of the state or any other jurisdiction).</p>
            <h4 #nineten2>
                19.2 Venue</h4>
            <p>
                Any legal suit, action or proceeding arising out of or related to this 
                Agreement or the Services shall be instituted exclusively in the federal 
                courts of the United States or the courts of the state in which ClickReview’s
                principal place of business is located.</p>
            <h4 #nineten3>
                19.3 No Waiver; Severability</h4>
            <p>
                The failure of ClickReview to enforce any right or provision of this 
                Agreement shall not constitute a waiver of such right or provision. If any 
                provision of this Agreement is held by a court of competent jurisdiction to 
                be invalid or unenforceable, the remaining provisions of this Agreement 
                shall remain in full force and effect.</p>
            <h4 #nineten4>
                19.4 Assignment</h4>
            <p>
                ClickReview may assign or delegate these Terms and/or the ClickReview 
                Services, in whole or in part, to any person or entity at any time with or 
                without your consent. You may not assign or delegate any rights or 
                obligations under these Terms or the ClickReview Services without 
                ClickReview’s prior written consent, and any unauthorized assignment and 
                delegation by you is void and ineffective.</p>
            <h4 #nineten5>
                19.5 Entire Agreement</h4>
            <p>
                These Terms and Conditions, together with the Privacy Policy, represent 
                the entire understanding and agreement between you and ClickReview 
                regarding the use of our Service and supersede any prior or 
                contemporaneous negotiations, discussions, or agreements, whether 
                written or oral, between you and ClickReview with respect to the Service. 
                Any waivers or amendments to these Terms and Conditions must be in 
                writing and signed by ClickReview.</p>
            <h4 #nineten6>
                19.6 Relationship between the Parties</h4>
            <p>
                The parties are independent contractors, and no agency, partnership, joint
                venture, employee-employer or franchiser-franchisee relationship is 
                intended or created by this Agreement. Neither party shall have the power
                to bind the other party or incur obligations on the other party’s behalf 
                without the other party’s prior written consent.</p>
            <h4 #nineten7>
                19.7 Export / Trade Compliance</h4>
            <p>
                You agree to comply with all applicable laws, regulations and rules, 
                including without limitation any export control laws, and that no part of 
                the Services may be exported or re-exported to any countries that are 
                embargoed or otherwise restricted by applicable laws, or to any person on 
                the U.S. Department of Commerce’s Denied Persons List or Entity List, the 
                U.S. Treasury Department’s Specially Designated Nationals List or the 
                Department of State’s Debarred Parties List. You represent and warrant 
                that you are not located in any such country or on any such list.</p>
            <h4 #nineten8>19.8 Third Party Beneficiary</h4>
            <p>
                Except as expressly provided herein, nothing in this Agreement is 
                intended, nor shall anything herein be construed to confer any rights in 
                any person other than the parties hereto and their respective successors 
                and permitted assigns. Notwithstanding the foregoing, if you are entering 
                into this Agreement on behalf of a Customer, your Customer may be a 
                third-party beneficiary of this Agreement, and your acceptance of the 
                terms and conditions of this Agreement will be treated as acceptance on 
                behalf of your Customer.</p>
            <h4 #nineten9>
                19.9 Notices</h4>
            <p>
                All notices to be given to ClickReview shall be given in writing to the 
                following address: ClickReview US LLC, 141 E Commercial blvd, Ft 
                Lauderdale, FL 33334. All notices to be given to you shall be given in 
                writing to the email address provided by you in the registration process. 
                Notice shall be deemed given upon receipt or 24 hours after email is sent, 
                unless the sending party is notified that the email address is invalid.</p>
            <h2 #twenty>20. Contacting Us</h2>
            <p>If you have any questions or comments about these Terms and Conditions 
                or the Services, you can contact us by emailing info@clickreview.us or by 
                using the contact form on our website.</p>
        </div>
    </div>
</div>