import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareerComponent } from './pages/career/career.component';
import { CompanyComponent } from './pages/company/company.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CountrySelectorComponent } from './pages/country-selector/country-selector.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { HowComponent } from './pages/how/how.component';
import { OrderComponent } from './pages/order/order.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SecurityComponent } from './pages/security/security.component';
import { SoftwareComponent } from './pages/software/software.component';
import { TermsComponent } from './pages/terms/terms.component';



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: ':lang/home', component:HomeComponent },
  { path: 'home', component:HomeComponent },
  { path: ':lang/country-selector', component:CountrySelectorComponent },
  { path: 'country-selector', component:CountrySelectorComponent },
  { path: ':lang/pricing', component:HowComponent },
  { path: 'pricing', component:HowComponent },
  { path: ':lang/about-us', component:CompanyComponent },
  { path: 'about-us', component:CompanyComponent },
  { path: ':lang/devices', component:OrderComponent },
  { path: 'devices', component:OrderComponent },
  { path: ':lang/career', component:CareerComponent },
  { path: 'career', component:CareerComponent },
  { path: ':lang/contact', component:ContactComponent },
  { path: 'contact', component:ContactComponent },
  { path: ':lang/software', component: SoftwareComponent },
  { path: 'software', component: SoftwareComponent },
  { path: ':lang/security', component: SecurityComponent },
  { path: 'security', component: SecurityComponent },
  { path: ':lang/terms-and-conditions', component: TermsComponent },
  { path: 'terms-and-conditions', component: TermsComponent },
  { path: ':lang/privacy', component: PrivacyComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: ':lang/faq', component: FaqComponent },
  { path: 'faq', component: FaqComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
