import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private _httpClient: HttpClient) { }


  sendMail(data): Promise<any[]> {
    console.log('uslo')
    console.log(data)
    return this._httpClient.post<any[]>('https://contact.nextgen.ag/contact', data).toPromise()
  }


  sendCv(data): Promise<any[]> {
    console.log('uslo')
    console.log(data)
    return this._httpClient.post<any[]>('https://cms.clickreview.pro/api/contactform', data).toPromise()
  }
}
