<!-- <div class="container">
    <div class="contact-wrapper">
        <div class="left-side">
            <h2>{{'Contact us' | translate}}</h2>
            <p class="title" [innerHTML]="'Fill up the form and our Team will get back to you within 24 hours.' | translate"></p>
            <div class="icon-wrapper">
                <img src="assets/images/phone-green.svg" alt="">
                <p>+381 64 16 00 007</p>
            </div>
            <div class="icon-wrapper">
                <img src="assets/images/email-green.svg" alt="">
                <p>info@clickreview.rs</p>
            </div>
            <div class="icon-wrapper">
                <img src="assets/images/location-green.svg" alt="">
                <p class="address-down">Hercegovačka 19, 11000 Beograd</p>
            </div>
            <div class="social-icons">
                <img src="assets/images/facebook-black.svg" alt="">
                <a href="https://www.instagram.com/clickreview.pro" target="_blank"><img src="assets/images/instagram-black.svg" alt=""></a>
            </div>
        </div>
        <div class="right-side" [formGroup]="contactForm" *ngIf="!formSuccess">
            <div class="input-wrapper">
                <p>{{'Your name:' | translate}}</p>
                <input type="text" formControlName="name">
            </div>
            <div class="input-wrapper">
                <p>{{'Your email:' | translate}}</p>
                <input type="text" formControlName="email">
            </div>
            <div class="input-wrapper">
                <p>{{'Your message:' | translate}}</p>
                <textarea name="" id="" cols="40" rows="15" formControlName="message"></textarea>
            </div>
            <div class="button-wrapper">
                <button (click)="send()">{{'SEND' | translate}}</button>
            </div>
        </div>
        <div class="right-side form-success" *ngIf="formSuccess">
            <h4>{{'Thanks for contacting us! We will be in touch with you shortly.' | translate}}</h4>
        </div>
    </div>
</div> -->

<div class="hero">
    <div class="container" style="text-align: left;">
        <div class="hero-content">
            <h5 class="top">{{'CONTACT US' | translate}}</h5>
            <h1>{{'We’d love to hear from you!' | translate}}</h1>
            <h3>{{'Get in touch via our simple form.' | translate}}</h3>
            <p>{{'Our team will reach out to answer your questions as soon as possible.' | translate}}</p>
        </div>
    </div>
    <!-- <div class="container"> -->
    <!-- <div class="bg-overlay"></div>
        <video [muted]="true" autoplay loop playsinline>
            <source src="assets/images/hero-new.mp4" type="video/mp4">
        </video>
        <div class="hero-content">
            <h1>{{'RAISE YOUR ONLINE RANKING TO THE NEXT LEVEL WITH CLICK REVIEW' | translate}}</h1>
            <p>{{'Click review’s unique solution allows you to improve your online reputation and visibility.' | translate}}</p>
            <button [routerLink]="['/contact']">{{'Order now' | translate}}</button>
        </div> -->
        <!-- <div class="hero-description">
            <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
            <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
                loyal social following – all in under a minute!</p>
            <button>GET MY CLICKREVIEW CARD</button>
        </div> -->
    <!-- </div> -->
</div>
<div class="main-wrapper">
    <div class="container">
        <div class="pricing" [formGroup]="contactForm">
            <div class="follow" *ngIf="!isMobile">
                <p class="b">Clickreview GmbH</p>
                <p>Riedmattstrasse 12</p>
                <p>6030 Ebikon</p>
            </div>
            <div class="price" *ngIf="!formSuccess">
                <div class="inputs-wrapper half">
                    <input type="text" placeholder="{{ 'First Name' | translate}}*" formControlName="name">
                    <input type="text" placeholder="{{ 'Last Name' | translate}}*" formControlName="lastname">
                </div>
                <div class="inputs-wrapper full">
                    <input type="text" placeholder="{{ 'E-mail' | translate}}*" formControlName="email">     
                </div>
                <div class="inputs-wrapper half">
                    <input type="text" placeholder="{{ 'Company name' | translate}}" formControlName="company">
                    <input type="text" placeholder="{{ 'Phone' | translate}}*" formControlName="phone">
                </div>
                <div class="inputs-wrapper full dropdown-wrap">
                    <div class="dropdown-option" (click)="openDrop()">
                        <span *ngIf="activeOption == ''">{{'How Can We Help You?' | translate}}</span>
                        <span *ngIf="activeOption != ''">{{activeOption | translate}}</span>
                        <img src="assets/images/arrow-down-blue.svg" alt="">
                        <div class="dropdown" *ngIf="isOpen">
                            <p (click)="chooseOption('Inquiry about our products/services'); onEvent($event)">{{'Inquiry about our products/services' | translate}}</p>
                            <p (click)="chooseOption('Technical support request'); onEvent($event)">{{'Technical support request' | translate}}</p>
                            <p (click)="chooseOption('Billing or payment inquiry'); onEvent($event)">{{'Billing or payment inquiry' | translate}}</p>
                            <p (click)="chooseOption('Partnership or collaboration proposal'); onEvent($event)">{{'Partnership or collaboration proposal' | translate}}</p>
                            <p (click)="chooseOption('Job application or career opportunity'); onEvent($event)">{{'Job application or career opportunity' | translate}}</p>
                            <p (click)="chooseOption('General feedback or suggestion'); onEvent($event)">{{'General feedback or suggestion' | translate}}</p>
                            <p (click)="chooseOption('Other')">{{'Other' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="inputs-wrapper full">
                    <textarea name="" id="" cols="30" rows="8" placeholder="Message" formControlName="message"></textarea>
                </div>
                <p><input type="checkbox" name="" id="" checked> {{'Yes, I’d like to receive the latest news and other communications from ClickReview. You can unsubscribe anytime. For more details, go to the Privacy Policy.' | translate}}</p>
                <div class="button-wrapper">
                    <button (click)="send()" [disabled]="contactForm.invalid" [ngClass]="{'disabled': contactForm.invalid}">{{'Submit' | translate}}</button>
                </div>
            </div>
            <div class="price" *ngIf="formSuccess">
                <h4>You have successfully sent us an email!</h4>
            </div>
            <div class="follow" *ngIf="isMobile">
                <p class="b">Clickreview GmbH</p>
                <p>Riedmattstrasse 12</p>
                <p>6030 Ebikon</p>
            </div>
        </div>
        <!-- <div class="listing">
            <h4>Transparent Pricing Guarantee</h4>
            <div class="texts">
                <div class="text">
                    <div class="icon-wrapper">
                        <img src="assets/images/blue-okay.svg" alt="">
                    </div>
                    <p>Transparent Pricing: No hidden fees or charges.</p>
                </div>
                <div class="text">
                    <div class="icon-wrapper">
                        <img src="assets/images/blue-okay.svg" alt="">
                    </div>
                    <p>Flexible Plans: Tailored to your specific needs.</p>
                </div>
                <div class="text">
                    <div class="icon-wrapper">
                        <img src="assets/images/blue-okay.svg" alt="">
                    </div>
                    <p>Price Lock Guarantee: The price you sign up for is the price you’ll pay.</p>
                </div>
            </div>
        </div> -->
    </div>
    <!-- <div class="container">
        <div class="intro-wrapper">
            <h2 [innerHTML]="'How Does ClickReview Work?' | translate"></h2>
            <div class="image-wrapper">
                <img src="assets/images/playy.svg" alt="" class="play-img" (click)="playVid()">
                <video controls *ngIf="lang === 'de'">
                    <source src="assets/images/how.mp4" type="video/mp4">
                </video>
                <video controls>
                    <source src="assets/images/how-en.mp4" type="video/mp4">
                </video>
                <img src="assets/images/video.png" alt="" class="main-img">
                
            </div>
        </div>
        <div class="crp-wrapper">
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW BOARD' | translate}}</h4>
                <h6 [innerHTML]="'No Installation. No Hassle!' | translate"></h6>
                <p>{{'All your customers need to do is bring their smartphone near the CLICKREVIEW board, and they’ll instantly be taken to your landing page to leave a great review in moments!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW PAGE' | translate}}</h4>
                <h6 [innerHTML]="'Online Platforms Your Customers Love!' | translate"></h6>
                <p>{{'We empower your customers to have their say on the world’s most popular online review platforms, all at the touch of a button. They can even follow you on the socials in a click!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper laptop">
                    <img src="assets/images/laptop.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW ADMIN PANEL' | translate}}</h4>
                <h6 [innerHTML]="'Monitor Your Progress and Collect Feedbacks!' | translate"></h6>
                <p>{{'Easily set your landing page up with accompanying CLICKREVIEW boards, track stats, and unlock feedback on your service quality in real-time!' | translate}}</p>
            </div>
        </div>
        <div class="options-wrapper">
            <div class="left-side">
                <h3 [innerHTML]="'Why Choose ClickReview Pro?' | translate"></h3>
                <ul>
                    <li>{{'Boost your average rating on the world’s most used review platforms, from Google to Facebook, TripAdvisor, and beyond' | translate}}</li>
                    <li>{{'Analyze consumer interest in existing and new products to determine your upsell potential' | translate}}</li>
                    <li>{{'Easily grow your following on social media without stretching your budget' | translate}}</li>
                    <li>{{'Effortlessly soar up search results' | translate}}</li>
                    <li>{{'Establish a positive image for your brand' | translate}}</li>
                    <li>{{'Earn more visibility and keep existing customers while winning new ones' | translate}}</li>
                    <li>{{'Measure customer satisfaction using specific segmentation' | translate}}</li>
                    <li>{{'Unlock your employee evaluation score to view work quality at a glance' | translate}}</li>
                    <li>{{'Get your Net Promoter Score (NPS®) to predict growth and measure customer experiences' | translate}}</li>
                </ul>
            </div>
            <div class="right-side">
                <img src="assets/images/phone-1.png" alt="" class="phone">
                <img src="assets/images/facebook.svg" class="icon facebook" alt="">
                <img src="assets/images/website.svg" class="icon website" alt="">
                <img src="assets/images/tripadvisor.svg" class="icon trip" alt="">
                <img src="assets/images/instagram.svg" class="icon instagram" alt="">
            </div>
        </div>
        <div class="button-wrapper">
            <button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button>
        </div>
        <div class="image-back">
            <img src="assets/images/clients-back.png" alt="">
            <div class="text-back">
                <h3 [innerHTML]="'Did you know that, on average, ClickReview clients get a staggering 500% MORE reviews?' | translate"></h3>
                <p>{{'With CLICKREVIEW, you can start bringing in countless real, authentic reviews and foster trust and faith in your business – all in a matter of seconds!' | translate}}</p>
            </div>
        </div>
        <div class="columns-wrapper">
            <div class="column">
                <h4 [innerHTML]="'GROW YOUR SOCIAL FOLLOWING IN NO TIME' | translate"></h4>
                <p>{{'CLICKREVIEW makes it easier than ever to for your customers to leave you great reviews online, so you can build a bigger, more loyal audience and send your follower count soaring.' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'INSPIRE YOUR TEAM TO IMPROVE' | translate"></h4>
                <p>{{'Seeing as each CLICKREVIEW board carries its own unique serial number, you can effortlessly track the number of reviews you get from each one. What better way to get your staff inspired to boost their productivity and deliver better quality of service? Customer satisfaction will soar, all thanks to CLICKREVIEW and you!' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'REAL-TIME CUSTOMER FEEDBACK' | translate"></h4>
                <p>{{'Consumers always want to see that businesses have a constant influx of positive reviews, meaning that real-time feedback is the key to loyal business-customer relationships. By peeking into your customers’ minds, you’ll also be able to dramatically improve your business based on their feedback.' | translate}}</p>
            </div>
        </div>
    </div> -->
    <!-- <app-subscribe></app-subscribe> -->

    <!-- <div class="container">
        <div class="references-wrapper">
            <img src="assets/images/st.regis.svg" alt="">
            <img src="assets/images/hilton.svg" alt="">
            <img src="assets/images/hyat.svg" alt="">
            <img src="assets/images/marrlott.svg" alt="">
            <img src="assets/images/Four_Seasons_logo Copy 3.svg" alt="">
        </div>
    </div> -->
</div>
