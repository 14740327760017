import { Component, OnInit, ViewChild, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild("mobCheck") divView: ElementRef;
  selectLang: string = 'de'
  language: boolean = false
  navIsFixed: boolean = false;
  mobIsFixed: boolean = false;
  mobLanguage: boolean = false;
  isOpen: boolean = false;
  isBlue: boolean = true;
  ispricing: boolean = false;
  drops: boolean = false;
  isCompany: boolean = false;
  isCareer: boolean = false;
  isContact: boolean = false;
  isAbout: boolean = false;
  @ViewChild('button') menu: ElementRef;

  constructor(public translate: TranslateService, private cookieService: CookieService, private router: Router, private renderer: Renderer2) {
    translate.use('de');
    router.events.subscribe((val) => {
        // see also 
        if(val instanceof NavigationEnd) {
          if(val.url == '/home' || val.url == '/') {
            this.isBlue = true;
          } else {
            this.isBlue = false;
          }
          if(val.url == '/pricing') { 
            this.ispricing = true;
          } else {
            this.ispricing = false;
          }
          if(val.url == '/about-us') { 
            this.isAbout = true;
          } else {
            this.isAbout = false;
          }
          if(val.url == '/contact') { 
            this.isContact = true;
          } else {
            this.isContact = false;
          }
          if(val.url == '/career') { 
            this.isCareer = true;
          } else {
            this.isCareer = false;
          }
          if(val.url == '/career' || val.url == '/contact' || val.url == '/about-us') { 
            this.isCompany = true;
          } else {
            this.isCompany = false;
          }
        }
    });

    this.renderer.listen('window', 'click',(e:Event)=>{
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if(this.menu) {
        if(e.target!==this.menu.nativeElement){
            this.isOpen=false;
        }
      }
    });
   }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(res => {
      this.selectLang = res.lang
    });
    if (localStorage.getItem('lang')) {
      this.translate.use(localStorage.getItem('lang'));
      this.selectLang = localStorage.getItem('lang')
    }
  }

  onMouseEnter() {
    if(this.drops == false) {
      this.drops = true;
    } else {
      this.drops = false;
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      if(window.innerWidth > 768) {
        this.navIsFixed = true;
        this.mobIsFixed = false;
      } else {
        this.mobIsFixed = true;
      }
    } else {
      this.mobIsFixed = false;
      this.navIsFixed = false;
    }
  }

  openDrop() {
    if(this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  onEvent(event) {
    event.stopPropagation();
  }

  closeMenu(){
    this.divView.nativeElement.checked = false
  }
  
  setTransLanguage(lang) {
    this.translate.use(lang);
    localStorage.removeItem('lang')
    localStorage.setItem('lang', lang)
    this.selectLang = lang
  }

  openLanguage() {
    this.language == false ? this.language = true : this.language = false;
  }

  openMobLanguage() {
    this.mobLanguage == false ? this.mobLanguage = true : this.mobLanguage = false;
  }
}
