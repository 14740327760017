<!-- <div class="hero">
    <div class="container">
        <div class="hero-description">
            <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
            <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
            <div class="social-icons">
                <div class="icons">
                    <img src="assets/images/instagram.svg" alt="">
                    <img src="assets/images/facebook.svg" alt="">
                    <img src="assets/images/google.svg" alt="">
                    <img src="assets/images/website.svg" alt="">
                    <img src="assets/images/tripadvisor.svg" alt="">
                    <img src="assets/images/tik-tok.svg" alt="">
                    <img src="assets/images/appstore.svg" alt="">
                    <img src="assets/images/google-play.svg" alt="">
                    <img src="assets/images/folder.svg" alt="">
                </div>
                <div class="text">{{'and a lot of more' | translate}}</div>
            </div>
        </div>
    </div>
</div>
<div class="mobile-description">
    <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
    <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
    <div class="social-icons">
        <img src="assets/images/instagram.svg" alt="">
        <img src="assets/images/facebook.svg" alt="">
        <img src="assets/images/google.svg" alt="">
        <img src="assets/images/website.svg" alt="">
        <img src="assets/images/tripadvisor.svg" alt="">
    </div>
</div>
<div class="container">
    <div class="review-text">
        <h4>{{'Never Miss Another Review' | translate}}</h4>
        <p>{{'Get YOUR Clickreview Card Today!' | translate}}</p>
    </div>
    <div class="card-wrapper">
        <div class="card">
            <h5>{{'CONTACT FOR PRICING' | translate}}</h5>
            <p>{{'Start a conversation with our team to find a ClickReview package as unique as your business!' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Send request' | translate | uppercase}}</button>
            </div>
        </div>
        <div class="card">
            <h5>{{'Need to chat? Got a question?' | translate | uppercase}}</h5>
            <p>{{'Simply fill out the form to the right, and our team will get back to you as soon as possible – usually within one business day.' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Contact us' | translate | uppercase}}</button>
            </div>
        </div>
    </div>
</div>
<app-subscribe></app-subscribe>
-->


<div class="hero" (window:resize)="onResize($event)">
    
    <div class="hero-content">
        <h1>{{'Make Review Collection Easy with 
            ClickReview’s NFC Devices.' | translate}}</h1>
        <p>{{'ClickReview’s NFC-based review collection device simplifies the process of collecting customer reviews and improving your online reputation. By making it easy for customers to leave a review, our device can help increase the number of reviews you receive and boost your business’s online reputation. Learn more about our device below.' | translate}}</p>
        <div class="button-wrapper">
            <button [routerLink]="['/pricing']">{{'Get your device' | translate}}</button>
        </div>
        <div class="img-abs">
            <img src="assets/images/device77.png" alt="">
        </div>
    </div>
    <!-- <div class="container"> -->
    <!-- <div class="bg-overlay"></div>
        <video [muted]="true" autoplay loop playsinline>
            <source src="assets/images/hero-new.mp4" type="video/mp4">
        </video>
        <div class="hero-content">
            <h1>{{'RAISE YOUR ONLINE RANKING TO THE NEXT LEVEL WITH CLICK REVIEW' | translate}}</h1>
            <p>{{'Click review’s unique solution allows you to improve your online reputation and visibility.' | translate}}</p>
            <button [routerLink]="['/contact']">{{'Order now' | translate}}</button>
        </div> -->
        <!-- <div class="hero-description">
            <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
            <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
                loyal social following – all in under a minute!</p>
            <button>GET MY CLICKREVIEW CARD</button>
        </div> -->
    <!-- </div> -->
</div>
<div class="main-wrapper">
    <div class="container">
        <div class="sides">
            <div class="side" *ngIf="!isMobile">
                <div class="right">
                    <div class="phones">
                        <div class="white">
                            <img src="assets/images/dev1.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="left">
                    <h2>{{'ClickReview Mobile Device' | translate}}</h2>
                    <p>{{'Our mobile device is the perfect solution for businesses with on-the-go employees. Our mobile device, which is based on NFC technology, empowers your employees to gather reviews instantly, while offering you full oversight and management of all your reviews. With our software, you can track every device, monitor reviews in real-time, and respond to customer feedback from anywhere. Plus, with the mobile device’s sleek, modern design, it’s the perfect addition to any professional setting.' | translate}}</p>
                </div>
            </div>
            <div class="side" *ngIf="isMobile">
                <div class="left">
                    <h2>{{'ClickReview Mobile Device' | translate}}</h2>
                    <p>{{'Our mobile device is the perfect solution for businesses with on-the-go employees. Our mobile device, which is based on NFC technology, empowers your employees to gather reviews instantly, while offering you full oversight and management of all your reviews. With our software, you can track every device, monitor reviews in real-time, and respond to customer feedback from anywhere. Plus, with the mobile device’s sleek, modern design, it’s the perfect addition to any professional setting.' | translate}}</p>
                </div>
                <div class="right">
                    <div class="phones">
                        <div class="white">
                            <img src="assets/images/dev1.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="side">
                <div class="left">
                    <h2>{{'ClickReview Stand Device' | translate}}</h2>
                    <p>{{'Our standing NFC device is the perfect tool to make review collection fast and easy. With its sleek wooden design, it not only looks great but it’s also simple to use. The device utilizes NFC technology to allow customers to leave reviews with just a tap of their phone, and with Clickreview’s powerful software, you can manage and analyze those reviews with ease. Plus, our device is designed to work seamlessly with any type of business, so you can start seeing the benefits right away.' | translate}}</p>
                </div>
                <div class="right">
                    <div class="phones">
                        <div class="white">
                            <img src="assets/images/dev.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="steps">
            <h2>{{'Easy 3-Step Process to Leave a Review' | translate}}</h2>
            <p>{{'Leaving a review has never been easier with ClickReview’s simple and intuitive process. In just three quick steps, customers can share their feedback and help your business grow.' | translate}}</p>

            <div class="step-cards">
                <!-- <div class="step-img"> -->
                    <!-- <img src="assets/images/steps.svg" alt=""> -->
                <!-- </div> -->
                <div class="step-texts">
                    <div class="texts">
                        <img src="assets/images/step1.svg" alt="">
                        <p class="title">{{'Tap a device' | translate}}</p>
                        <p class="text">{{'The first step in leaving a review is as simple as tapping the NFC-enabled device.' | translate}}</p>
                    </div>
                    <div class="texts">
                        <img src="assets/images/step2.svg" alt="">
                        <p class="title">{{'Open the Pop-Up' | translate}}</p>
                        <p class="text">{{'When prompted, simply open the popup on your phone.' | translate}}</p>
                    </div>
                    <div class="texts">
                        <img src="assets/images/steps3.svg" alt="">
                        <p class="title">{{'Leave a review' | translate}}</p>
                        <p class="text">{{'Once the pop-up is open, customers can easily leave a review with just a click.' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container">
        <div class="intro-wrapper">
            <h2 [innerHTML]="'How Does ClickReview Work?' | translate"></h2>
            <div class="image-wrapper">
                <img src="assets/images/playy.svg" alt="" class="play-img" (click)="playVid()">
                <video controls *ngIf="lang === 'de'">
                    <source src="assets/images/how.mp4" type="video/mp4">
                </video>
                <video controls>
                    <source src="assets/images/how-en.mp4" type="video/mp4">
                </video>
                <img src="assets/images/video.png" alt="" class="main-img">
                
            </div>
        </div>
        <div class="crp-wrapper">
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW BOARD' | translate}}</h4>
                <h6 [innerHTML]="'No Installation. No Hassle!' | translate"></h6>
                <p>{{'All your customers need to do is bring their smartphone near the CLICKREVIEW board, and they’ll instantly be taken to your landing page to leave a great review in moments!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW PAGE' | translate}}</h4>
                <h6 [innerHTML]="'Online Platforms Your Customers Love!' | translate"></h6>
                <p>{{'We empower your customers to have their say on the world’s most popular online review platforms, all at the touch of a button. They can even follow you on the socials in a click!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper laptop">
                    <img src="assets/images/laptop.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW ADMIN PANEL' | translate}}</h4>
                <h6 [innerHTML]="'Monitor Your Progress and Collect Feedbacks!' | translate"></h6>
                <p>{{'Easily set your landing page up with accompanying CLICKREVIEW boards, track stats, and unlock feedback on your service quality in real-time!' | translate}}</p>
            </div>
        </div>
        <div class="options-wrapper">
            <div class="left-side">
                <h3 [innerHTML]="'Why Choose ClickReview Pro?' | translate"></h3>
                <ul>
                    <li>{{'Boost your average rating on the world’s most used review platforms, from Google to Facebook, TripAdvisor, and beyond' | translate}}</li>
                    <li>{{'Analyze consumer interest in existing and new products to determine your upsell potential' | translate}}</li>
                    <li>{{'Easily grow your following on social media without stretching your budget' | translate}}</li>
                    <li>{{'Effortlessly soar up search results' | translate}}</li>
                    <li>{{'Establish a positive image for your brand' | translate}}</li>
                    <li>{{'Earn more visibility and keep existing customers while winning new ones' | translate}}</li>
                    <li>{{'Measure customer satisfaction using specific segmentation' | translate}}</li>
                    <li>{{'Unlock your employee evaluation score to view work quality at a glance' | translate}}</li>
                    <li>{{'Get your Net Promoter Score (NPS®) to predict growth and measure customer experiences' | translate}}</li>
                </ul>
            </div>
            <div class="right-side">
                <img src="assets/images/phone-1.png" alt="" class="phone">
                <img src="assets/images/facebook.svg" class="icon facebook" alt="">
                <img src="assets/images/website.svg" class="icon website" alt="">
                <img src="assets/images/tripadvisor.svg" class="icon trip" alt="">
                <img src="assets/images/instagram.svg" class="icon instagram" alt="">
            </div>
        </div>
        <div class="button-wrapper">
            <button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button>
        </div>
        <div class="image-back">
            <img src="assets/images/clients-back.png" alt="">
            <div class="text-back">
                <h3 [innerHTML]="'Did you know that, on average, ClickReview clients get a staggering 500% MORE reviews?' | translate"></h3>
                <p>{{'With CLICKREVIEW, you can start bringing in countless real, authentic reviews and foster trust and faith in your business – all in a matter of seconds!' | translate}}</p>
            </div>
        </div>
        <div class="columns-wrapper">
            <div class="column">
                <h4 [innerHTML]="'GROW YOUR SOCIAL FOLLOWING IN NO TIME' | translate"></h4>
                <p>{{'CLICKREVIEW makes it easier than ever to for your customers to leave you great reviews online, so you can build a bigger, more loyal audience and send your follower count soaring.' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'INSPIRE YOUR TEAM TO IMPROVE' | translate"></h4>
                <p>{{'Seeing as each CLICKREVIEW board carries its own unique serial number, you can effortlessly track the number of reviews you get from each one. What better way to get your staff inspired to boost their productivity and deliver better quality of service? Customer satisfaction will soar, all thanks to CLICKREVIEW and you!' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'REAL-TIME CUSTOMER FEEDBACK' | translate"></h4>
                <p>{{'Consumers always want to see that businesses have a constant influx of positive reviews, meaning that real-time feedback is the key to loyal business-customer relationships. By peeking into your customers’ minds, you’ll also be able to dramatically improve your business based on their feedback.' | translate}}</p>
            </div>
        </div>
    </div> -->
    <!-- <app-subscribe></app-subscribe> -->

    <!-- <div class="container">
        <div class="references-wrapper">
            <img src="assets/images/st.regis.svg" alt="">
            <img src="assets/images/hilton.svg" alt="">
            <img src="assets/images/hyat.svg" alt="">
            <img src="assets/images/marrlott.svg" alt="">
            <img src="assets/images/Four_Seasons_logo Copy 3.svg" alt="">
        </div>
    </div> -->
</div>

<div class="faq-wrapper">
    <div class="container">

        <div class="faq">
            <h5 class="top">{{'FAQ' | translate}}</h5>
            <h3>{{'You’ve got questions. We’ve got answers.' | translate}}</h3>
            <div class="tabordion">
                <section id="section1" #sectionOne>
                    <label id="option1" (click)="showtab('one')"><span [ngClass]="{rotate : firstTabState=='open'}">+</span>
                        <p>{{'What is ClickReview’s review collection stand device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=firstTabState>
                        <article>
                            <p>{{'ClickReview’s NFC-based review collection device is a tool that allows businesses to easily collect customer reviews by placing the device in a prominent location at their business and inviting customers to tap their NFC-enabled phone to leave a review.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section2" #sectionTwo>
                    <label id="option2" (click)="showtab('two')"><span
                            [ngClass]="{rotate : secondTabState=='open'}">+</span>
                        <p>{{'What is ClickReview’s mobile device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=secondTabState>
                        <article>
                            <p>{{'ClickReview’s mobile device is a tool that allows employees to easily collect customer reviews while they work. It is a lightweight, portable device that can be carried by employees throughout their shift.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section3" #sectionThree>
                    <label id="option3" (click)="showtab('three')"><span
                            [ngClass]="{rotate : thirdTabState=='open'}">+</span>
                        <p>{{'How does the device work?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=thirdTabState>
                        <article>
                            <p>{{'The device works by using NFC technology to provide customers with a convenient landing page where they can leave a review on the review platform of their choice.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section4" #sectionFour>
                    <label id="option4" (click)="showtab('four')"><span
                            [ngClass]="{rotate : fourthTabState=='open'}">+</span>
                        <p>{{'What devices are compatible with ClickReview’s device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=fourthTabState>
                        <article>
                            <p>{{'ClickReview’s device is compatible with any smartphone that has NFC capabilities.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section5" #section5>
                    <label id="option5" (click)="showtab('five')"><span
                            [ngClass]="{rotate : five=='open'}">+</span>
                        <p>{{'Do I need to download an app to use ClickReview’s device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=five>
                        <article>
                            <p>{{'No, there is no need to download an app to use ClickReview’s device. Customers can simply tap their NFC-enabled phone to the device to leave a review.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section6" #section6>
                    <label id="option6" (click)="showtab('six')"><span
                            [ngClass]="{rotate : six=='open'}">+</span>
                        <p>{{'Can I customize the landing page that customers see when they tap their phone to the device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=six>
                        <article>
                            <p>{{'Yes, ClickReview allows businesses to customize the landing page that customers see when they tap their phone to the device.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section7" #section7>
                    <label id="option7" (click)="showtab('seven')"><span
                            [ngClass]="{rotate : seven=='open'}">+</span>
                        <p>{{'How do I access and manage the reviews that are collected using the device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=seven>
                        <article>
                            <p>{{'All reviews collected using the device are automatically synced to your ClickReview review management system, where you can monitor and respond to customer reviews.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section8" #section8>
                    <label id="option8" (click)="showtab('eight')"><span
                            [ngClass]="{rotate : eight=='open'}">+</span>
                        <p>{{'How does ClickReview’s review management system help me manage my online reputation?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=eight>
                        <article>
                            <p>{{'ClickReview’s review management system provides businesses with a comprehensive and user-friendly platform for monitoring and managing reviews from multiple platforms, including Google, Yelp, TripAdvisor and many more.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section9" #section9>
                    <label id="option9" (click)="showtab('nine')"><span
                            [ngClass]="{rotate : nine=='open'}">+</span>
                        <p>{{'Can I monitor my reviews and manage my account from any device?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=nine>
                        <article>
                            <p>{{'Yes, ClickReview’s review management system is cloud-based and accessible from any device with a web browser.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section10" #section10>
                    <label id="option10" (click)="showtab('ten')"><span
                            [ngClass]="{rotate : ten=='open'}">+</span>
                        <p>{{'What happens if a customer leaves a negative review?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=ten>
                        <article>
                            <p>{{'ClickReview’s review management system allows you to monitor and respond to all customer reviews, including negative reviews. Responding to negative reviews in a professional and courteous manner can help mitigate the impact of the negative review.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section11" #section11>
                    <label id="option11" (click)="showtab('eleven')"><span
                            [ngClass]="{rotate : eleven=='open'}">+</span>
                        <p>{{'How much does ClickReview’s NFC-based device cost?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=eleven>
                        <article>
                            <p>{{'Pricing for ClickReview’s NFC-based device varies based on the plan you choose. Please visit our Pricing page for more information.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<app-subscribe></app-subscribe>