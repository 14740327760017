

<div class="hero">

    <div class="hero-content">
        <img src="assets/images/sec1.svg" alt="" class="sec1">
        <img src="assets/images/sec2.svg" alt="" class="sec2">
        <h5 class="top">{{'SECURITY' | translate}}</h5>
        <h1>{{'ClickReview’s Commitment to 
            Security and Privacy' | translate}}</h1>
        <p>{{'At ClickReview, we take the security and privacy of our clients seriously. Our software is designed with industry-standard security protocols and encryption to ensure that your data is protected and safe. Our cloud-based review management system is hosted on secure servers, and all data is encrypted in transit and at rest.' | translate}}</p>
    </div>

</div>

<div class="container">
    <div class="main-wrapper">
        <div class="cards">
            <div class="card">
                <div class="img">
                    <img src="assets/images/sesir.svg" alt="">
                </div>
                <p>
                    {{'We understand the importance of privacy and the need for businesses to maintain the confidentiality of their customer data. That’s why we do not collect any personal or identifiable information from your customers during the review collection process. All data collected using our NFC-based device or mobile device is anonymous, and we do not track or store any information about your customers.' | translate}}
                </p>
            </div>
            <div class="card">
                <div class="img">
                    <img src="assets/images/security.svg" alt="">
                </div>
                <p>
                    {{'We also employ a number of measures to ensure that our software is secure and free from vulnerabilities. Our software is regularly tested and audited by third-party security firms to identify and remediate any potential security risks. We also keep our software up-to-date with the latest security patches and updates to ensure that our clients are protected from the latest threats.' | translate}}
                </p>
            </div>
        </div>

        <p class="paragraph">
            {{'At ClickReview, our commitment to security and privacy is paramount. We understand the importance of maintaining the trust of our clients, and we strive to provide a secure and reliable review management system that our clients can rely on.' | translate}}
        </p>

        <div class="text">
            <h5 class="top">{{'HELP US MAKE CLICKREVIEW MORE SECURE' | translate}}</h5>
            <h3 class="title">{{'Questions about security?' | translate}}</h3>
            <p class="tex">
                {{'Keeping our clients data secure is an absolute top priority at ClickReview. Our goal is to provide a secure environment, while also being mindful of application performance and the overall user experience. To email us with a vulnerability or other security concern, send an email to security@clickreview.com' | translate}}
            </p>
            <div class="btn">
                <button>{{'Contact us' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>