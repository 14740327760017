import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private scrolls: ScrollService) { }

  ngOnInit(): void {
    this.scrolls.setScroll()
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth",block: "center", inline: "nearest" });
  }

}
