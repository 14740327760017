<div class="footer">
    <div class="container up">
        <div class="column">
            <div class="aligner">
                <img src="assets/images/logo-white-1.svg" alt="" class="logo">
                <p>{{"With ClickReview, you'll be able to show the whole world how good you are. Let us help you skyrocket your digital reputation in no time" | translate}}</p>
                <!-- <div class="icons">
                    <img src="assets/images/footer1.svg" alt="">
                    <img src="assets/images/footer2.svg" alt="">
                    <img src="assets/images/footer3.svg" alt="">
                    <img src="assets/images/footer4.svg" alt="">
                </div> -->
            </div>
        </div>
        <!-- <div class="column"> -->
            <!-- <h4>Contact</h4> -->
        <!-- </div> -->
        <div class="column link">
            <div class="aligner">
                <h4>{{'SHORTCUTS' | translate}}</h4>
                <p [routerLink]="['/security']">{{'Security' | translate}}</p>
                <p [routerLink]="['/pricing']">{{'Pricing' | translate}}</p>
                <p [routerLink]="['/login']">{{'Sign in' | translate}}</p>
            </div>
        </div>
        <div class="column link">
            <div class="aligner">
                <h4>{{'Company' | translate | uppercase}}</h4>
                <p [routerLink]="['/career']">{{'Careers' | translate}}</p>
                <p [routerLink]="['/about-us']">{{'About Us' | translate}}</p>
                <p [routerLink]="['/contact']">{{'Contact Us' | translate}}</p>
            </div>
        </div>
        <div class="column link">
            <div class="aligner">
                <h4>{{'RESOURCES'| translate}}</h4>
                <!-- <p [routerLink]="['/home']">{{'Support' | translate}}</p> -->
                <p [routerLink]="['/faq']">{{'FAQ' | translate}}</p>
            </div>
        </div>
    </div>
    <div class="container down">
        <div class="left">
            <span [routerLink]="['/privacy']">Privacy</span>
            <span [routerLink]="['/terms-and-conditions']">Terms</span>
            <!-- <span>Legal</span> -->
        </div>
        <div class="copy">© 2023 Clickreview. All rights reserved</div>
    </div>
</div>