import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';

@Component({
  selector: 'app-how',
  templateUrl: './how.component.html',
  styleUrls: ['./how.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class HowComponent implements OnInit {
  lang;
  firstTabState = 'closed'
  secondTabState = 'closed'
  thirdTabState = 'closed'
  fourthTabState = 'closed'
  five = 'closed'
  six = 'closed'
  seven = 'closed'
  eight = 'closed'
  nine = 'closed'
  blueFirst: boolean = false;
  greenFirst: boolean = true;

  constructor(private scroll: ScrollService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    this.lang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.lang = res.lang
    });
  }


  playVid() {
    let vid = <HTMLVideoElement> document.getElementById("myVideo");
    vid.play();
  }

  switchBlue() {
    if(this.blueFirst == false) {
      this.blueFirst = true;
    } else {
      this.blueFirst = false;
    }
  }

  switchGreen() {
    if(this.greenFirst == false) {
      this.greenFirst = true;
    } else {
      this.greenFirst = false;
    }
  }

  pauseVid() {
    let vid = <HTMLVideoElement> document.getElementById("myVideo");
      vid.pause();
  }

  onEvent(event) {
    event.stopPropagation();
  }
  
  showtab(type) {
    if (type == 'one') {
      this.firstTabState = this.firstTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'two') {
      this.secondTabState = this.secondTabState === 'closed' ? 'open' : 'closed';
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'three') {
      this.thirdTabState = this.thirdTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'four') {
      this.fourthTabState = this.fourthTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'five') {
      this.five = this.five === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'six') {
      this.six = this.six === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'seven') {
      this.seven = this.seven === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'eight') {
      this.eight = this.eight === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.six = 'closed'
      this.nine = 'closed'
    }
    if (type == 'nine') {
      this.nine = this.nine === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.six = 'closed'
    }
  }

}
