import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { trigger, state, style, animate, transition, } from '@angular/animations';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class OrderComponent implements OnInit {
  firstTabState = 'closed'
  secondTabState = 'closed'
  thirdTabState = 'closed'
  fourthTabState = 'closed'
  five = 'closed'
  six = 'closed'
  seven = 'closed'
  eight = 'closed'
  nine = 'closed'
  isMobile: boolean = false;
  ten = 'closed'
  eleven = 'closed'

  constructor(private scroll: ScrollService) { }

  ngOnInit(): void {
    this.scroll.setScroll();
    if(window.innerWidth < 769) { 
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  
  onResize(event) {
    if(event.target.innerWidth < 769) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  showtab(type) {
    if (type == 'one') {
      this.firstTabState = this.firstTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
    }
    if (type == 'two') {
      this.secondTabState = this.secondTabState === 'closed' ? 'open' : 'closed';
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
    }
    if (type == 'three') {
      this.thirdTabState = this.thirdTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
    }
    if (type == 'four') {
      this.fourthTabState = this.fourthTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
    }
    if (type == 'five') {
      this.five = this.five === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
    }
    if (type == 'six') {
      this.six = this.six === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
    }
    if (type == 'seven') {
      this.seven = this.seven === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
    }
    if (type == 'eight') {
      this.eight = this.eight === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.six = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
    }
    if (type == 'nine') {
      this.nine = this.nine === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.six = 'closed'
    }
    if (type == 'ten') {
      this.ten = this.ten === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.eleven = 'closed'
      this.six = 'closed'
    }
    if (type == 'eleven') {
      this.eleven = this.eleven === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
    }
  }
}
