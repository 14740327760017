<!-- <div class="hero">
    <div class="container">
        <div class="hero-description">
            <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
            <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
            <div class="social-icons">
                <div class="icons">
                    <img src="assets/images/instagram.svg" alt="">
                    <img src="assets/images/facebook.svg" alt="">
                    <img src="assets/images/google.svg" alt="">
                    <img src="assets/images/website.svg" alt="">
                    <img src="assets/images/tripadvisor.svg" alt="">
                    <img src="assets/images/tik-tok.svg" alt="">
                    <img src="assets/images/appstore.svg" alt="">
                    <img src="assets/images/google-play.svg" alt="">
                    <img src="assets/images/folder.svg" alt="">
                </div>
                <div class="text">{{'and a lot of more' | translate}}</div>
            </div>
        </div>
    </div>
</div>
<div class="mobile-description">
    <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
    <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
    <div class="social-icons">
        <img src="assets/images/instagram.svg" alt="">
        <img src="assets/images/facebook.svg" alt="">
        <img src="assets/images/google.svg" alt="">
        <img src="assets/images/website.svg" alt="">
        <img src="assets/images/tripadvisor.svg" alt="">
    </div>
</div>
<div class="container">
    <div class="review-text">
        <h4>{{'Never Miss Another Review' | translate}}</h4>
        <p>{{'Get YOUR Clickreview Card Today!' | translate}}</p>
    </div>
    <div class="card-wrapper">
        <div class="card">
            <h5>{{'CONTACT FOR PRICING' | translate}}</h5>
            <p>{{'Start a conversation with our team to find a ClickReview package as unique as your business!' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Send request' | translate | uppercase}}</button>
            </div>
        </div>
        <div class="card">
            <h5>{{'Need to chat? Got a question?' | translate | uppercase}}</h5>
            <p>{{'Simply fill out the form to the right, and our team will get back to you as soon as possible – usually within one business day.' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Contact us' | translate | uppercase}}</button>
            </div>
        </div>
    </div>
</div>
<app-subscribe></app-subscribe>
-->


<div class="hero">

    <div class="hero-content">
        <div class="icon a">
            <img src="assets/images/career1.svg" alt="">
        </div>
        <div class="icon b">
            <img src="assets/images/career2.svg" alt="">
        </div>
        <div class="icon c">
            <img src="assets/images/career3.svg" alt="">
        </div>
        <div class="icon d">
            <img src="assets/images/career4.svg" alt="">
        </div>
        <h5 class="top">{{'CAREERS' | translate}}</h5>
        <h1>{{'Join the ClickReview Team' | translate}}</h1>
        <p>{{'ClickReview is seeking talented and motivated sales professionals to help us grow our business. As a leading provider of review management software and devices, we’re passionate about helping businesses improve their online reputation and connect with their customers.' | translate}}</p>
    </div>
    <!-- <div class="container"> -->
    <!-- <div class="bg-overlay"></div>
        <video [muted]="true" autoplay loop playsinline>
            <source src="assets/images/hero-new.mp4" type="video/mp4">
        </video>
        <div class="hero-content">
            <h1>{{'RAISE YOUR ONLINE RANKING TO THE NEXT LEVEL WITH CLICK REVIEW' | translate}}</h1>
            <p>{{'Click review’s unique solution allows you to improve your online reputation and visibility.' | translate}}</p>
            <button [routerLink]="['/contact']">{{'Order now' | translate}}</button>
        </div> -->
    <!-- <div class="hero-description">
            <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
            <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
                loyal social following – all in under a minute!</p>
            <button>GET MY CLICKREVIEW CARD</button>
        </div> -->
    <!-- </div> -->
</div>
<div class="main-wrapper">
    <div class="container">
        <div class="text-sides">
            <div class="text-side">
                <p class="uptitle">{{'JOIN US' | translate}}</p>
                <h2>{{'Our Commitments' | translate}}</h2>
                <p class="text">
                    {{'At ClickReview, we’re committed to fostering a supportive and collaborative work environment that encourages personal and professional growth. We believe in investing in our employees and providing them with the resources and support they need to succeed. We offer competitive compensation packages, ongoing training and development opportunities, and a fun and rewarding work culture. We value diversity and inclusion, and we strive to create a workplace where everyone feels welcomed and supported. At ClickReview, we’re dedicated to helping our employees achieve their full potential and thrive in their careers.' | translate}}
                </p>
            </div>
            <div class="block-side">
                <div class="img">
                    <div class="square">
                        <img src="assets/images/green-kocka.svg" alt="">
                    </div>
                    <img src="assets/images/blocks2.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faq-wrapper">
    <div class="container">
        <h3>{{'Word from our employees' | translate}}</h3>
        <div class="slider">
            <div class="card">
                <img src="assets/images/apos.svg" alt="" class="apos">
                <p class="desc">{{'I am constantly challenged as I work with some of the best minds in the industry, and this motivates me to do better everyday. Overall, I love being part of an incredible team.' | translate}}</p>
                <p class="name">Jordan H</p>
            </div>
        </div>
        <div class="pag">
            <span class="active"></span>
            <span></span>
            <span></span>
            <span></span>
        </div>

    </div>
</div>

<div class="container">
    <div class="career">
        <h5 class="top">{{'LOVE YOUR WORK. JOIN THE DREAM TEAM' | translate}}</h5>
        <h3>{{'Be Part of Something Great' | translate}}</h3>
        <p>{{'We offer a collaborative and supportive work environment, competitive compensation packages, and ongoing opportunities for professional growth and development. If you’re a self-starter with a passion for sales and a desire to succeed, we’d love to hear from you. Check out our current openings and apply today!' | translate}}</p>

        <!-- <div class="positions">
            <div class="position">
                <div class="left">
                    <h4 class="positiond">{{'Sales Manager' | translate}}</h4>
                    <h4 class="city">Miami, Florida, United States of America</h4>
                </div>
                <div class="right">
                    <button (click)="openModal('Sales Manager')">{{'Apply' | translate}}</button>
                </div>
            </div>
            <div class="position">
                <div class="left">
                    <h4 class="positiond">{{'Sales Agent' | translate}}</h4>
                    <h4 class="city">Miami, Florida, United States of America</h4>
                </div>
                <div class="right">
                    <button (click)="openModal('Sales Agent')">{{'Apply' | translate}}</button>
                </div>
            </div>
            <div class="position">
                <div class="left">
                    <h4 class="positiond">{{'Sales Agent' | translate}}</h4>
                    <h4 class="city">Miami, Florida, United States of America</h4>
                </div>
                <div class="right">
                    <button (click)="openModal('Sales Agent')">{{'Apply' | translate}}</button>
                </div>
            </div>
        </div> -->
    </div>
</div>
<app-subscribe></app-subscribe>

<div class="modal-wrap modal-closed" *ngIf="modalVisible" (click)="closeModal()">
    <div class="modal-cnt-wrap" [formGroup]="contactForm" >
        <div class="modal-cnt" (click)="onEvent($event)" *ngIf="!formSuccess">
            <div class="inputs-wrapper half">
                <input type="text" placeholder="First Name" formControlName="name">
                <input type="text" placeholder="Last Name" formControlName="lastname">
            </div>
            <div class="inputs-wrapper half">
                <input type="text" placeholder="E-Mail" formControlName="email">
                <input type="text" placeholder="Phone" formControlName="phone">
            </div>
            <div class="inputs-wrapper half">
                <input type="text" placeholder="Date of birth" formControlName="date">
                <input type="text" placeholder="Previous job" formControlName="job">
            </div>
            <div class="inputs-wrapper full">
                <input type="text" placeholder="Linkedin Profile (optional)" formControlName="linkedin">
            </div>
            <div class="inputs-wrapper full">
                <textarea name="" id="" cols="30" rows="8" placeholder="Message" formControlName="message"></textarea>
            </div>
            
            <div class="upload-btn-wrapper" *ngIf="!selectedFileGallery">
                <button class="btn">Upload CV <img src="assets/images/link.svg" alt=""></button>
                <input type="file" name="myfile" #imageInput (change)="processFile(imageInput); onSelectFile($event)"/>
            </div>
            <div class="error" *ngIf="pdfError">
                File must be in PDF Format!
            </div>

            <div class="img-pdf" *ngIf="selectedFileGallery">
                <img src="assets/images/bluepdf.svg" alt="">
            </div>
            <div class="text">
                <p>
                    ClickReview will not share any of provided information with any third party. For more details, go to the Privacy Policy.</p>
            </div>
            <div class="button-wrapper">
                <button (click)="send()" [disabled]="contactForm.invalid" [ngClass]="{'disabled': contactForm.invalid}">Apply</button>
            </div>
        </div>
        <div class="modal-cnt" (click)="onEvent($event)" *ngIf="formSuccess">
            <h3>You have successfully submitted your application!</h3>
        </div>
    </div>
</div>
