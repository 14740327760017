
<div class="update-wrapper" [formGroup]="contactForm">
    <div class="container contis">
        <div class="left">
            <h3>{{'Stay In-The-Know' | translate}}</h3>
            <p>{{'Be the first to know about our latest news, updates, and special offers. No third-party emails or spam – promise.' | translate}}</p>
        </div>
        <div class="input-wrapper" *ngIf="!formSuccess">
            <input type="text" placeholder="{{'Please provide your e-mail address here' | translate}}" [email]="true" formControlName="email" [ngClass]="{'error': f.email.errors && f.email.touched}">
            <button (click)="send()">{{'SIGN ME UP' | translate | uppercase}}</button>
            <div class="error-message" *ngIf="f.email.invalid && f.email.touched">
                <span *ngIf="f.email.errors?.email">{{'Your email is not correct! Check your email and try again.' | translate}}</span>
            </div>
            <div class="error-message" *ngIf="f.email.invalid && f.email.touched">
                <span *ngIf="f.email.errors?.required">{{'Your email field is empty!' | translate}}</span>
            </div>
        </div>
        <div class="success" *ngIf="formSuccess">
            <h4>{{'You have successfully subscribed for our newsletter' | translate}}</h4>
        </div>
    </div>
</div>