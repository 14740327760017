

<div class="hero">

    <div class="hero-content">
        <h1>{{'Revolutionize Your Review Management 
            with ClickReview' | translate}}</h1>
        <p>{{'The ClickReview Software is a powerful tool that allows you to monitor and manage all of your reviews in one place. With our user-friendly interface, you can track your reviews, respond to customers, and improve your online reputation with ease. Our software is designed to save you time and simplify the review management process. Learn more about our software and its features below.' | translate}}</p>
        <div class="button-wrapper">
            <!-- <button [routerLink]="['/contact']">{{'Take a tour' | translate}}</button> -->
            <button [routerLink]="['/pricing']" class="device">{{'Get your device' | translate}}</button>
        </div>
        <div class="img">
            <img src="assets/images/Dashboard-1.svg" alt="">
        </div>
    </div>
</div>

<div class="container">
    <div class="blog">
        <div class="blog-left">
            <div class="header">
                <div class="img-wr">
                    <img src="assets/images/dash-icon.svg" alt="">
                </div>
                {{'DASHBOARD' | translate}}
            </div>
            <h3>{{'Get a Comprehensive Overview' | translate}}</h3>
                <p>{{'The ClickReview Dashboard provides a quick and easy overview of your online reputation, displaying important metrics such as your total number of reviews, average rating, and recent review activity. Our user-friendly interface allows you to navigate to any section of the system with ease, making it simple to monitor and manage your reviews from one location.' | translate}}</p>
        </div>
        <div class="blog-right">
            <img src="assets/images/Dashboard-1.svg" alt="">
        </div>
    </div>
    <div class="blog">
        <div class="blog-left">
            <div class="header">
                <div class="img-wr">
                    <img src="assets/images/dash-icon.svg" alt="">
                </div>
                {{'Employee Management' | translate}}
            </div>
            <h3>{{'Streamline Employee Review Collection with ClickReview' | translate}}</h3>
                <p>{{'ClickReview’s Employee Management and Overview feature streamlines the review collection process and provides real-time insights into employee performance. With our device, employees can easily collect reviews, and our system allows you to monitor each employee’s review collection progress.' | translate}}</p>
        </div>
        <div class="blog-right">
            <img src="assets/images/list.svg" alt="">
        </div>
    </div>

    <div class="green-box">
        <h3>{{'ClickReview simplifies review management by bringing everything to one place.' | translate}}</h3>
        <p>{{'By bringing employee review collection, online review monitoring, and review response management together in one system, ClickReview provides a comprehensive and efficient review management solution for businesses of all sizes.' | translate}}</p>
        <div class="green-card">
            <div class="left">
                <div class="header">
                    <div class="img-wr">
                        <img src="assets/images/shuttle.svg" alt="">
                    </div>
                </div>
                <h3 class="tit">{{'Start your day with quick overview' | translate}}</h3>
                    <p class="tit">{{'Start your day with the quick overview of the progress your company is making!' | translate}}</p>
            </div>
            <div class="right">
                <h5>{{'Welcome back' | translate}},</h5>
                <h4>Jonathan</h4>
                <h6>{{'Here are some quick updates for you today!' | translate}}</h6>
                <div class="icons-wrap">
                    <div class="icon">
                        <div class="img green">
                            <img src="assets/images/white-user.svg" alt="">
                        </div>
                        <div class="text">
                            <p>{{'New Clients' | translate}}</p>
                            <p class="num">18</p>
                        </div>
                    </div>
                    <div class="icon">
                        <div class="img blue">
                            <img src="assets/images/white-sound.svg" alt="">
                        </div>
                        <div class="text">
                            <p class="blue">{{'New Leads' | translate}}</p>
                            <p class="num blue">22</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="white-card">
            <div class="left">
                <div class="header">
                    <div class="img-wr">
                        <img src="assets/images/responsive.svg" alt="">
                    </div>
                </div>
                <h3 class="tit">{{'Access Your Review Management System Anywhere, Anytime' | translate}}</h3>
                    <p class="tit">{{'Access your review management system anywhere and anytime with ClickReview’s cloud-based platform, available on any device with a web browser.' | translate}}</p>
            </div>
            <div class="right">
                <img src="assets/images/devices.svg" alt="">
            </div>
        </div>
    </div>

    <div class="central">
        <h2>{{'Centralize all your work processes' | translate}}</h2>

        <div class="central-cards">
            <div class="central-card">
                <div class="img">
                    <img src="assets/images/central1.svg" alt="">
                </div>
                <h3>{{'Dashboard' | translate}}</h3>
                <p>{{'View all your reviews across multiple platforms in one place' | translate}}
                </p>
                <div class="line"></div>
                 <p>{{'Monitor review activity, including new reviews and changes in rating' | translate}}
                </p>
                <div class="line"></div>
                 <p>{{'Respond to customer reviews directly from the dashboard' | translate}}</p>
            </div>
            <div class="central-card">
                <div class="img">
                    <img src="assets/images/central2.svg" alt="">
                </div>
                <h3>{{'Employees' | translate}}</h3>
                <p>{{'Assign review collection devices to your employees' | translate}}
                </p>
                <div class="line"></div>
                 <p>{{'Track the number of reviews each employee has collected in real-time' | translate}}
                </p>
                <div class="line"></div>
                 <p>{{'Easily manage and update employee accounts from the admin software' | translate}}</p> 
            </div>
            <div class="central-card">
                <div class="img">
                    <img src="assets/images/central3.svg" alt="">
                </div>
                <h3>{{'Platform' | translate}}</h3>
                <p>{{'Monitor and respond to reviews from multiple platforms, including Google, Yelp, TripAdvisor and more' | translate}}
                </p>
                <div class="line"></div>
                 <p>{{'Stay up-to-date on your online reputation with real-time notifications' | translate}}
                </p>
                <div class="line"></div>
                 <p>{{'Easily access detailed metrics and insights about your online reputation on each platform.' | translate}}</p>
            </div>
        </div>
    </div>

    <div class="blue-box">
        <h3>{{'Take Control of Your Online Reputation with ClickReview!' | translate}}</h3>
            <p>{{'Take control of your online reputation with ClickReview’s review management system and review collection device. Our powerful features and user-friendly interface make it easy to streamline your review management process and boost your online reputation. Choose ClickReview today and watch your business grow.' | translate}}</p>

            <button [routerLink]="['/pricing']">{{'GET YOUR DEVICE' | translate}}</button>
    </div>
</div>
<app-subscribe></app-subscribe>