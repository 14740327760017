import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isMobile: boolean = false;
  displayedCard: any[] = [];

  test: any[] = [{
    "text": "Our online reputation has never been better since we started using ClickReview. The device is incredibly easy to use, and our customers appreciate how convenient it is to leave a review. We’ve seen a significant increase in positive reviews across all platforms, which has translated into a higher visibility on Google. I highly recommend ClickReview to any business looking to improve their online reputation", "photo": "anna.svg", "firstName": "Anna", "lastName": "Richards", "position": "Digital Marketing Manager, Selz",
  },{
    "text": "As a busy restaurant owner, I don’t have the time to manage our online reputation on my own. That’s why we turned to ClickReview, and it’s been a game-changer for us. The device makes it easy for our customers to leave a review, and the admin panel allows us to monitor our reputation and respond to reviews in real-time. We’ve seen a dramatic increase in positive reviews, which has helped us attract new customers and keep our regulars coming back.", "photo": "ben.svg", "firstName": "Ben","lastName": "Simmons", "position": "Senior Director, BSS",
  },{
    "text": "I was skeptical at first about using ClickReview, but I’m so glad we gave it a try. The device is so easy to use that our customers don’t even need to ask us for help. And the landing page makes it simple for them to leave a review on the platform of their choice. We’ve noticed a significant increase in positive reviews, and it’s helped us stand out in a crowded market. I would recommend ClickReview to anyone looking to improve their online reputation.", "photo": "jason.svg", "firstName": "Jason","lastName": "Mull", "position": "Digital Marketing Manager, EKM",
  },{
    "text": "I was skeptical at first about using ClickReview, but I’m so glad we gave it a try. The device is so easy to use that our customers don’t even need to ask us for help. And the landing page makes it simple for them to leave a review on the platform of their choice. We’ve noticed a significant increase in positive reviews, and it’s helped us stand out in a crowded market. I would recommend ClickReview to anyone looking to improve their online reputation.", "photo": "jason.svg", "firstName": "Jason","lastName": "Mull", "position": "Digital Marketing Manager, EKM",
  },]

  constructor(private scroll: ScrollService, public translate: TranslateService, private cookieService: CookieService, private route: ActivatedRoute, private router: Router) {

    this.displayedCard = this.test.slice(0, 3);
   }

  ngOnInit(): void {
    this.scroll.setScroll()
    var snapshot = this.route.snapshot;
    const params = { ...snapshot.params };
    if(params.lang) {
      this.translate.use(params.lang)
      delete params.lang
      this.router.navigate(['/home'], { queryParams: params });
    }
    if(window.innerWidth < 769) { 
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  onResize(event) {
    if(event.target.innerWidth < 769) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
