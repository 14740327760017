import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class CareerComponent implements OnInit {
  firstTabState = 'closed'
  secondTabState = 'closed'
  thirdTabState = 'closed'
  fourthTabState = 'closed'
  five = 'closed'
  six = 'closed'
  seven = 'closed'
  eight = 'closed'
  nine = 'closed'
  modalVisible: boolean = false
  contactForm: FormGroup
  formSuccess: boolean = false;
  selectedFile: Blob
  fileName: any = null
  fileUrls: any[] = []
  selectedFileGallery: File = null;
  galleryUpload: boolean = false;
  pdfError: boolean = false;
  selectedPosition: string = ''
  selectedFiles: any[] = []

  constructor(private scroll: ScrollService, private mailService: MailService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    this.contactForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(2)]),
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lastname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      job: new FormControl('', [Validators.required, Validators.minLength(2)]),
      date: new FormControl('', [Validators.required, Validators.minLength(2)]),
      linkedin: new FormControl(''),
      phone: new FormControl('', [Validators.required, Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }

  showtab(type) {
    if (type == 'one') {
      this.firstTabState = this.firstTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'two') {
      this.secondTabState = this.secondTabState === 'closed' ? 'open' : 'closed';
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'three') {
      this.thirdTabState = this.thirdTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'four') {
      this.fourthTabState = this.fourthTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'five') {
      this.five = this.five === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'six') {
      this.six = this.six === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'seven') {
      this.seven = this.seven === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
    }
    if (type == 'eight') {
      this.eight = this.eight === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.six = 'closed'
      this.nine = 'closed'
    }
    if (type == 'nine') {
      this.nine = this.nine === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.six = 'closed'
    }
  }

  get f() {
    return this.contactForm.controls;
  }

  send() {
    let formParams = new FormData();
    formParams.append('file', this.selectedFileGallery)
    formParams.append('firstName', this.f.name.value)
    formParams.append('lastName', this.f.lastname.value)
    formParams.append('email', this.f.email.value)
    formParams.append('Phone', this.f.phone.value)
    formParams.append('Previous Job', this.f.job.value)
    formParams.append('Linkedin Profile', this.f.linkedin.value)
    formParams.append('Message', this.f.message.value)
    formParams.append('position', this.selectedPosition)
    let formData = 
    {
      "firstName": this.f.name.value,
      "lastName": this.f.lastname.value,
      "email": this.f.email.value,
      "Previous Job": this.f.job.value,
      "Phone": this.f.phone.value,
      "Linkedin Profile": this.f.linkedin.value,
      "Message": this.f.message.value,
      "file": this.selectedFileGallery,
      "position": this.selectedPosition
    }

    console.log(formData)

    if (this.contactForm.valid) {
      this.formSuccess = true;
      this.mailService.sendCv(formParams).then((data)=>{

      })
      setTimeout(() => {
        this.modalVisible = false;
      }, 2000);
    } else {
      console.log("error");
    }
  }

  openModal(position) {
    this.selectedPosition = position;
    this.modalVisible = true;
  }

  closeModal() {
    this.modalVisible = false
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  
  onSelectFile(e) {
    if (e.target.files && e.target.files[0]) {
      var filesAmount = e.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.fileUrls.push(event.target.result);
        }
        console.log("fileUrls nakon dodavanja", this.fileUrls)
        this.galleryUpload = true
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  }
  
  processFile(imageInput: any) {
    console.log(imageInput)
    let file = <File>imageInput.files[0];
    if(file.type == 'application/pdf') {
      this.pdfError = false;
      this.selectedFileGallery = <File>imageInput.files[0];
      this.selectedFiles.push(this.selectedFileGallery)
      console.log("dodata jos jedna", this.selectedFiles)
    } else {
      this.pdfError = true;
    }
  }
}
