import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { ScrollService } from 'src/app/services/scroll.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class FaqComponent implements OnInit {
  firstTabState = 'closed'
  secondTabState = 'closed'
  thirdTabState = 'closed'
  fourthTabState = 'closed'
  five = 'closed'
  six = 'closed'
  seven = 'closed'
  eight = 'closed'
  nine = 'closed'
  ten = 'closed'
  eleven = 'closed'
  new1 = 'closed'
  new2 = 'closed'
  new3 = 'closed'
  new4 = 'closed'
  new5 = 'closed'
  new6 = 'closed'
  new7 = 'closed'
  new8 = 'closed'
  new9 = 'closed'
  input;

  constructor(private scroll: ScrollService) { }

  ngOnInit(): void {
    this.scroll.setScroll();
  }

  onKey() {
    const ele = document.getElementsByTagName('section');
    for (let i = 0; i < ele.length; i++) {
      const element = ele[i];
      if(this.input == '') {
        if(!element.classList.contains('display')){ 
          element.classList.add('display')
        }
      } else {
        if(!element.innerHTML.includes(this.input)) {
          element.classList.remove('display')
        } else {
          if(!element.classList.contains('display')){ 
            element.classList.add('display')
          }
        }
      }
    }
  }

  showtab(type) {
    if (type == 'one') {
      this.firstTabState = this.firstTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'two') {
      this.secondTabState = this.secondTabState === 'closed' ? 'open' : 'closed';
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'three') {
      this.thirdTabState = this.thirdTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'four') {
      this.fourthTabState = this.fourthTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.five = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'five') {
      this.five = this.five === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'six') {
      this.six = this.six === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'seven') {
      this.seven = this.seven === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.six = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'eight') {
      this.eight = this.eight === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.six = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.nine = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'nine') {
      this.nine = this.nine === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.eleven = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'ten') {
      this.ten = this.ten === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.nine = 'closed'
      this.eleven = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'eleven') {
      this.eleven = this.eleven === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new1') {
      this.new1 = this.new1 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.eleven = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new2') {
      this.new2 = this.new2 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.eleven = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new3') {
      this.new3 = this.new3 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.eleven = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new4') {
      this.new4 = this.new4 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.eleven = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new5') {
      this.new5 = this.new5 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.eleven = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new6') {
      this.new6 = this.new6 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.eleven = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new7') {
      this.new7 = this.new7 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.eleven = 'closed'
      this.new8 = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new8') {
      this.new8 = this.new8 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.eleven = 'closed'
      this.new9 = 'closed'
    }
    if (type == 'new9') {
      this.new9 = this.new9 === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
      this.five = 'closed'
      this.thirdTabState = 'closed'
      this.seven = 'closed'
      this.eight = 'closed'
      this.ten = 'closed'
      this.nine = 'closed'
      this.six = 'closed'
      this.new1 = 'closed'
      this.new2 = 'closed'
      this.new3 = 'closed'
      this.new4 = 'closed'
      this.new5 = 'closed'
      this.new6 = 'closed'
      this.new7 = 'closed'
      this.new8 = 'closed'
      this.eleven = 'closed'
    }
  }
}
