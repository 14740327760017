
<div class="hero">

    <div class="hero-content">
        <h1>{{'Privacy' | translate}}</h1>
    </div>

</div>

<div class="container">
    <p class="paragraph">
        {{'At ClickReview, we are committed to protecting the privacy of our clients and their customers. We recognize the importance of maintaining the confidentiality and security of personal information, and we take the responsibility of safeguarding this information seriously.' | translate}}
    </p>
    <div class="section">
        <div class="left">
            <h2>{{'SECURITY & PRIVACY' | translate}}</h2>
            <div class="line"></div>
            <p>{{'When you use our review management software and devices, we may collect certain data from your customers, such as their IP address, email address, and the content of their reviews. This data is used solely for the purpose of managing and monitoring customer reviews, and is never shared with any third parties.In addition, we may collect certain data from our clients, such as their name, email address, and payment information. This data is used solely for the purpose of managing client accounts and providing customer service, and is never shared with any third parties.' | translate}}</p>
        </div>
        <div class="right">
            <div class="img">
                <div class="side">
                    <img src="assets/images/privacy.svg" alt="">
                </div>
                <img src="assets/images/lock.svg" alt="" class="main">
            </div>
        </div>
    </div>
</div>

<div class="container-wrapper">
    <div class="container">
        <div class="texts">
            <h2>{{'We care about your Privacy' | translate}}</h2>
            <p>{{'We use industry-standard security protocols and encryption to protect the data that we collect, and we regularly review and update our security practices to ensure that we are using the most up-to-date security measures.' | translate}}
                <br><br>
                {{'ClickReview is committed to complying with all applicable data privacy laws and regulations, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).' | translate}}</p>
                <img src="assets/images/care.svg" alt="">
        </div>
    </div>
</div>

<div class="container">

    <div class="text">
        <h3 class="title">{{'Feel free to ask us a question.' | translate}}</h3>
        <p class="tex">
            {{'If you have any questions or concerns about our privacy practices, please do not hesitate to contact us. We’re always happy to help.' | translate}}
        </p>
        <div class="btn">
            <button>{{'Contact us' | translate}}</button>
        </div>
    </div>

</div>

<app-subscribe></app-subscribe>