import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  isMobile: boolean = false;
  contactForm: FormGroup
  activeOption: string = '';
  isOpen: boolean = false;
  formSuccess: boolean = false;
  constructor(private scroll: ScrollService, private mailService: MailService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    this.contactForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(2)]),
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lastname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      company: new FormControl(''),
      phone: new FormControl('', [Validators.required, Validators.minLength(2)]),
      message: new FormControl(''),
    });

    if(window.innerWidth < 769) { 
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  get f() {
    return this.contactForm.controls;
  }

  send() {
    let formData = 
    {
      "First Name": this.f.name.value,
      "Last Name": this.f.lastname.value,
      "Email": this.f.email.value,
      "Company Name": this.f.company.value,
      "Phone": this.f.phone.value,
      "How Can We Help You?": this.activeOption,
      "Message": this.f.message.value,

    }

    if (this.contactForm.valid) {
      this.formSuccess = true;
      this.mailService.sendCv(formData).then((data)=>{
        setTimeout(() => {
          this.formSuccess = false;
        }, 3000);
      })
    } else {
      console.log("error");
    }
  }

  openDrop() {
    if(this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  chooseOption(option: string) {
    this.activeOption = option;
    this.openDrop();
  }

  onEvent(event) {
    event.stopPropagation();
  }

  onResize(event) {
    if(event.target.innerWidth < 769) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
