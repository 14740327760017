<div class="hero" (window:resize)="onResize($event)">
    <!-- <div class="container"> -->
    <!-- <div class="bg-overlay"></div>
        <video [muted]="true" autoplay loop playsinline>
            <source src="assets/images/hero-new.mp4" type="video/mp4">
        </video>
        <div class="hero-content">
            <h1>{{'RAISE YOUR ONLINE RANKING TO THE NEXT LEVEL WITH CLICK REVIEW' | translate}}</h1>
            <p>{{'Click review’s unique solution allows you to improve your online reputation and visibility.' | translate}}</p>
            <button [routerLink]="['/contact']">{{'Order now' | translate}}</button>
        </div> -->
        <!-- <div class="hero-description">
            <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
            <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
                loyal social following – all in under a minute!</p>
            <button>GET MY CLICKREVIEW CARD</button>
        </div> -->
    <!-- </div> -->
    <div class="hero-content">
        <h1>{{'Unleash the Power of Customer Reviews with ClickReview' | translate}}</h1>
        <p>{{'Boost Your Business with Effortless Review Management - Our innovative device and software allow you to seamlessly monitor and control your online reputation, so you can focus on providing excellent customer service.' | translate}}</p>
        <button [routerLink]="['/pricing']">{{'Try now' | translate}}</button>

        <div class="device-wrapper">
            <img src="assets/images/instagram.svg" class="icon instagram" alt="">
            <img src="assets/images/google.svg" class="icon google" alt="">
            <!-- <img src="assets/images/appstore.svg" class="icon appstore" alt=""> -->
            <img src="assets/images/tik-tok.svg" class="icon tiktok" alt="">
            <div class="device">
                <img src="assets/images/device1.svg" alt="">
            </div>
            <img src="assets/images/facebook.svg" class="icon facebook" alt="">
            <img src="assets/images/website.svg" class="icon website" alt="">
            <img src="assets/images/tripadvisor.svg" class="icon trip" alt="">
            <img src="assets/images/yandex.svg" class="icon play" alt="">
            <img src="assets/images/yelp.svg" class="icon folder" alt="">
        </div>
    </div>
</div>
<!-- <div class="mobile-description">
    <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
    <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
        loyal social following – all in under a minute!</p>
    <button>GET MY CLICKREVIEW CARD</button>
</div> -->
<div class="container-card-wrapper">
    <div class="container card-container">
        <!-- <div class="hero-description">
            <h2 [innerHTML]="'Building customer reviews has NEVER been this easy!' | translate"></h2>
            <p [innerHTML]="'With ClickReview, you can easily boost your online review count while building a loyal social following – all in under a 30 seconds!' | translate"></p>
            <div class="btn-wrapper"><button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button></div>
        </div> -->
        <!-- <h4>{{'Did you know that…' | translate}}</h4> -->
        <div class="card-wrapper">
            <div class="card">
                <h5>{{'BOOST YOUR RATINGS' | translate}}</h5>
                <p>{{'Positive reviews can also help build trust with potential customers, making them more likely to choose your business over competitors with lower ratings. With Clickreview’s NFC-based device and software, collecting and managing reviews has never been easier, allowing you to take full advantage of the benefits of positive customer feedback.' | translate}}</p>
                <div class="card-line"></div>
            </div>
            <div class="card">
                <h5>{{'Improved Customer Trust' | translate}}</h5>
                <p>{{'A BrightLocal survey found that 91% of consumers trust online reviews as much as personal recommendations. By managing your online reputation and collecting positive reviews, you can build trust with potential customers and improve the likelihood of them choosing your business over competitors.'| translate}}</p>
                <div class="card-line"></div>
            </div>
            <div class="card">
                <h5>{{'Higher Search Engine Rankings' | translate}}</h5>
                <p>{{'Online reviews play a significant role in search engine optimization (SEO & Local SEO). Businesses with more positive reviews and higher ratings are more likely to appear at the top of search results, which can lead to increased website traffic and visibility. In fact, a study by Moz found that reviews are responsible for 13% of how Google ranks local search results.' | translate}}</p>
                <div class="card-line"></div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="sides">
        <div class="side">
            <div class="left">
                <h2>{{'Our ClickReview devices' | translate}}</h2>
                <p>{{'Our NFC-based device makes it incredibly easy for customers to leave a review for your business. All they have to do is tap their phone to the device, and they’ll be taken directly to a landing page with short links to all the top review platforms. No more searching for your business on various websites or trying to navigate complicated review forms. With our device, customers can quickly and easily leave a review, helping to improve your online reputation and attract new customers.' | translate}}</p>
            </div>
            <div class="right">
                <div class="phones">
                    <div class="phone-text full">
                        <img src="assets/images/logo-2.svg" alt="">
                        <p>{{'Let us know about Your expirience!' | translate}}</p>
                        <p class="sub">{{'TAP TO OPEN' | translate}}</p>
                    </div>
                    <div class="white">
                        <img src="assets/images/phones.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="side" *ngIf="!isMobile">
            <div class="right">
                <div class="phones">
                    <div class="icon like">👍</div>
                    <div class="icon heart">❤️</div>
                    <div class="icon start">⭐️</div>
                    <div class="phone-text girl">
                        <img src="assets/images/women.png" alt="">
                        <p>{{'Amazing place, we had so much fun
                            spending time here!' | translate}} 😃🥳</p>
                    </div>
                    <div class="phone-text girl2">
                        <img src="assets/images/women1.png" alt="">
                        <p>{{'Absolutelty delightfull experience.
                            Food was amazing and tastefull!' | translate}} 🍝🥗</p>
                    </div>
                    <div class="white">
                        <img src="assets/images/empty-phone.png" alt="">
                    </div>
                </div>
            </div>
            <div class="left">
                <h2>{{'Take Control of Your Online Reputation' | translate}}</h2>
                <p>{{'Our advanced admin panel gives you complete control over your business’s online reputation. With our system, you can easily monitor and manage all of the devices used by your employees to collect reviews, track each employee’s performance, and respond to reviews from all of the top review platforms. Our user-friendly interface makes it easy to keep track of your business’s online reputation in real-time, so you can respond quickly and effectively to any negative feedback or customer concerns. With our admin panel, you can take control of your online reputation and ensure that your business is always putting its best foot forward.' | translate}}</p>
            </div>
        </div>
        <div class="side" *ngIf="isMobile">
            <div class="left">
                <h2>{{'Take Control of Your Online Reputation' | translate}}</h2>
                <p>{{'Our advanced admin panel gives you complete control over your business’s online reputation. With our system, you can easily monitor and manage all of the devices used by your employees to collect reviews, track each employee’s performance, and respond to reviews from all of the top review platforms. Our user-friendly interface makes it easy to keep track of your business’s online reputation in real-time, so you can respond quickly and effectively to any negative feedback or customer concerns. With our admin panel, you can take control of your online reputation and ensure that your business is always putting its best foot forward.' | translate}}</p>
            </div>
            <div class="right">
                <div class="phones">
                    <div class="icon like">👍</div>
                    <div class="icon heart">❤️</div>
                    <div class="icon start">⭐️</div>
                    <div class="phone-text girl">
                        <img src="assets/images/women.png" alt="">
                        <p>{{'Amazing place, we had so much fun
                            spending time here!' | translate}} 😃🥳</p>
                    </div>
                    <div class="phone-text girl2">
                        <img src="assets/images/women1.png" alt="">
                        <p>{{'Absolutelty delightfull experience.
                            Food was amazing and tastefull!' | translate}} 🍝🥗</p>
                    </div>
                    <div class="white">
                        <img src="assets/images/empty-phone.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="side">
            <div class="left">
                <h2>{{'Streamline the Review Process' | translate}}</h2>
                <p>{{'Our landing page is a one-stop-shop for all of your business’s review needs. When customers tap their phone to our device, they’ll be directed to a user-friendly landing page that provides easy access to all of the top review platforms, including Google, Yelp, Trip Advisor, Instagram and more. From there, customers can leave a review with just a click, without having to navigate multiple websites or deal with complicated forms.' | translate}}</p>
            </div>
            <div class="right">
                <div class="phones">
                    <img src="assets/images/dash5.svg" class="dash a" alt="">
                    <img src="assets/images/dash.svg" class="dash c" alt="">
                    <img src="assets/images/dash2.svg" class="dash b" alt="">
                    <img src="assets/images/dash3.svg" class="dash d" alt="">
                    <img src="assets/images/dash4.svg" class="dash e" alt="">
                    <img src="assets/images/dash6.svg" class="dash f" alt="">
                    <div class="white dash">
                        <img src="assets/images/Dashboard.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="phone-wrapper">
        <div class="phone-part">
            <img src="assets/images/instagram.svg" class="icon instagram" alt="">
            <img src="assets/images/google.svg" class="icon google" alt="">
            <img src="assets/images/appstore.svg" class="icon appstore" alt="">
            <img src="assets/images/tik-tok.svg" class="icon tiktok" alt="">
            <div class="left-phone">
                <h4 [innerHTML]="'You Know Your Services Rule. But Do Your Customers?' | translate"></h4>
                <p>{{"With ClickReview, you'll be able to show the whole world how good you are. Let us help you skyrocket
                    your digital reputation in no time" | translate}}.</p>
            </div>
        </div>
        <div class="phone-part">
            <img class="phone" src="assets/images/cards.png" alt="">
        </div>
        <div class="phone-part">
            <img src="assets/images/facebook.svg" class="icon facebook" alt="">
            <img src="assets/images/website.svg" class="icon website" alt="">
            <img src="assets/images/tripadvisor.svg" class="icon trip" alt="">
            <img src="assets/images/google-play.svg" class="icon play" alt="">
            <img src="assets/images/folder.svg" class="icon folder" alt="">
            <div class="left-phone right">
                <h4 [innerHTML]="'ClickReview is the Answer…' | translate"></h4>
                <p>{{'Having a great business isn’t enough if no one knows about it. With ClickReview, you can confidently
                    give the world a reason to choose you again and again, because your online reputation will speak for
                    itself!' | translate}}</p>
            </div>
        </div>
    </div> -->
</div>

<div class="green-wrapper">
    <div class="container">
        <h2>{{'The Power of Positive Reviews: How They Benefit Your Business' | translate}}</h2>
        <h5>{{'Positive reviews can have a tremendous impact on your business. They can increase revenue, improve customer trust, boost employee morale, and even lead to higher search engine rankings. Here are six brief facts that highlight the benefits of positive reviews, and show just how important they are for the success of your business.' | translate}}</h5>
        <div class="sections">
            <div class="section">
                <p class="title">91%</p>
                <p class="text">{{'91% of consumers trust online reviews as much as personal recommendations (BrightLocal).' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">5-9%</p>
                <p class="text">{{'5-9% increase in revenue (Harvard Business School).' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">13%</p>
                <p class="text">{{'Positive reviews increase visibility by 13% in search results (Moz).' | translate}}</p>
            </div>
        </div>
        <div class="sections">
            <div class="section">
                <p class="title">270%</p>
                <p class="text">{{'Our system has increased the number of ratings for our clients by 150% on popular review platforms.' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">71%</p>
                <p class="text">{{'Customers who read positive reviews are 71% more likely to leave a review themselves (Podium).' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">5%</p>
                <p class="text">{{'Businesses with a one-star increase in their rating have a 5% increase in employee retention (Glassdoor).' | translate}}</p>
            </div>
        </div>
        <div class="sections last">
            <div class="section">
                <p class="title">50%</p>
                <p class="text">{{'Effective online reputation management can reduce customer acquisition costs by up to 50% (HubSpot).' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">87%</p>
                <p class="text">{{'87% of consumers read online reviews before making a purchase (Deloitte).' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">58%</p>
                <p class="text">{{'58% of consumers are willing to pay more for a business with positive reviews (Podium).' | translate}}</p>
            </div>
        </div>
        <h2 class="feed-title">{{'Our customers feedback' | translate}}</h2>
        <div class="cards-review">
            <div class="card-review" *ngFor="let image of displayedCard; let i = index;">
                <p class="desc">
                    {{image.text | translate}}
                </p>
                <div class="bottom">
                    <div class="img">
                        {{image.firstName[0]}}{{image.lastName[0]}}
                    </div>
                    <!-- <img src="assets/images/{{image.photo}}" alt=""> -->
                    <div class="name">
                        <p>{{image.firstName}} {{image.lastName}}</p>
                        <p class="sub">{{image.position}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pag">
            <span class="active"></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div>

<div class="container-card-wrapper">
    <div class="container card-container">
        <div class="pug">
            <h3>{{'Plug & Play' | translate}}</h3>
            <h6>{{'ClickReview supports all popular platforms, just link your profile in the admin panel!' | translate}}</h6>
            <div class="icons">
                <div class="icon"><img src="assets/images/instagram.svg" alt=""></div>
                <div class="icon"><img src="assets/images/yelp.svg" alt=""></div>
                <div class="icon"><img src="assets/images/tiktok.svg" alt=""></div>
                <div class="icon"><img src="assets/images/google.svg" alt=""></div>
                <div class="icon"><img src="assets/images/facebook.svg" alt=""></div>
                <div class="icon"><img src="assets/images/website.svg" alt=""></div>
                <div class="icon"><img src="assets/images/yandex.svg" alt=""></div>
                <div class="icon"><img src="assets/images/tripadvisor.svg" alt=""></div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container"></div> -->
<!-- <div class="button-wrapper">
    <button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button>
</div> -->
<!-- <app-subscribe></app-subscribe> -->
<!-- <div class="container">
    <div class="references-wrapper">
        <img src="assets/images/st.regis.svg" alt="">
        <img src="assets/images/hilton.svg" alt="">
        <img src="assets/images/hyat.svg" alt="">
        <img src="assets/images/marrlott.svg" alt="">
        <img src="assets/images/Four_Seasons_logo Copy 3.svg" alt="">
    </div>
</div> -->

<app-subscribe></app-subscribe>