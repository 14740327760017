<div class="nav-container">
    <div class="nav" [ngClass]="{'blue': isBlue,'fixed': navIsFixed, 'mobFixed': mobIsFixed}">
        <div class="container up-container" *ngIf="!navIsFixed">
            <div class="up-header">
                <p [ngClass]="{'blue': !isBlue}" [routerLink]="['/faq']">FAQ</p>
                <div class="p" [ngClass]="{'blue': !isBlue}" (click)="openDrop()" (focusout)="openDrop()" #button>{{selectLang | uppercase}}
                    <div class="dropdown" *ngIf="isOpen">
                        <p (click)="setTransLanguage('de')"><img src="assets/images/de.svg" alt=""> Deutsch</p>
                        <p (click)="setTransLanguage('en')"><img src="assets/images/en.svg" alt=""> English</p>
                        <p (click)="setTransLanguage('fr')"><img src="assets/images/fr.svg" alt=""> Français</p>
                        <p (click)="setTransLanguage('it')"><img src="assets/images/it.svg" alt=""> Italiano</p>
                    </div>
                </div>
                <img src="assets/images/arrow-down-blue.svg" alt="" class="arr" [ngClass]="{'blue': isBlue}">
            </div>
        </div>
        <div class="container">
            <div class="nav-wrapper">
                <div class="menu">
                    <div class="logo-wrapper" [routerLink]="['/home']">
                        <img src="assets/images/logo-white.svg" alt="" *ngIf="!navIsFixed&&isBlue">
                        <img src="assets/images/logo-black.svg" alt="" *ngIf="navIsFixed||!isBlue">
                    </div>
                    <div class="menu-nav">
                        <ul>
                            <li><a [routerLink]="['/home']" routerLinkActive="active-link">{{"Home" | translate}}</a></li>
                            <li><a [routerLink]="['/pricing']" routerLinkActive="active-link">{{"Pricing" | translate}}</a></li>
                            <li><a [routerLink]="['/devices']" routerLinkActive="active-link">{{"Devices" | translate}}</a></li>
                            <li (mouseenter)="onMouseEnter()" (mouseleave)="onMouseEnter()"><a [ngClass]="{'active-link': isCompany}">{{'Company' | translate}}</a>
                                <div class="dropdown" *ngIf="drops">
                                    <div class="links">
                                        <p [routerLink]="['/career']" [ngClass]="{'active-link': isCareer, 'more': selectLang == 'de' || selectLang == 'fr'}">{{'Career' | translate}}</p>
                                        <p [routerLink]="['/about-us']" [ngClass]="{'active-link': isAbout, 'more': selectLang == 'de' || selectLang == 'fr'}">{{'About Us' | translate}}</p>
                                        <p [routerLink]="['/contact']" [ngClass]="{'active-link': isContact, 'more': selectLang == 'de' || selectLang == 'fr'}">{{'Contact Us' | translate}}</p>
                                    </div>
                                    <img src="assets/images/drop.svg" alt="" [ngClass]="{'more': selectLang == 'de' || selectLang == 'fr'}">
                                </div>
                            </li>
                            <li><a [routerLink]="['/software']" routerLinkActive="active-link">{{'Software' | translate}}</a></li>
                            <!-- <li class="login"><a href="https://app.clickreview.pro/login" routerLinkActive="active-link">Login</a></li> -->
                            <!-- <li class="languages-dropdown" (click)="openLanguage()">
                                <p>{{selectLang | uppercase}}</p>
                                <img class="main-icon" src="assets/images/gr.svg" alt="" *ngIf="selectLang === 'gr'">
                                <img class="main-icon" src="assets/images/en.svg" alt="" *ngIf="selectLang === 'en'">
                                <img src="assets/images/black-arrow-down.svg" alt="" class="arrow">
                                <ul class="dropdown-ul" *ngIf="language">
                                    <li (click)="setTransLanguage('gr')">GR <img src="assets/images/gr.svg" alt=""></li>
                                    <li (click)="setTransLanguage('en')">EN <img src="assets/images/en.svg" alt=""></li>
                                </ul>
                            </li> -->
                        </ul>
                        <div class="cleaner"></div>
                    </div>
                    <div id="menuToggle">
                        <input type="checkbox" #mobCheck />
                        <span class="line" [ngClass]="{'blue': isBlue }"></span>
                        <span class="line" [ngClass]="{'blue': isBlue }"></span>
                        <span class="line" [ngClass]="{'blue': isBlue }"></span>
                        <ul id="menu" [ngClass]="{'blue': isBlue}">
                            <li><a [routerLink]="['/home']" (click)="closeMenu()"
                                    routerLinkActive="active-link">{{'Home' | translate}}</a></li>
                            <li><a [routerLink]="['/pricing']" (click)="closeMenu()"
                                    routerLinkActive="active-link">{{'Pricing' | translate}}</a></li>
                            <li><a [routerLink]="['/devices']" (click)="closeMenu()"
                                    routerLinkActive="active-link">{{'Devices' | translate}}</a></li>
                            <li><a [routerLink]="['/career']" (click)="closeMenu()"
                                    routerLinkActive="active-link">{{'Career' | translate}}</a></li>
                            <li><a [routerLink]="['/about-us']" (click)="closeMenu()"
                                    routerLinkActive="active-link">{{'About Us' | translate}}</a></li>
                            <li><a [routerLink]="['/contact']" (click)="closeMenu()"
                                    routerLinkActive="active-link">{{'Contact Us' | translate}}</a></li>
                            <li><a [routerLink]="['/software']" (click)="closeMenu()"
                                    routerLinkActive="active-link">{{'Software' | translate}}</a></li>
                            <!-- <li><a [routerLink]="['/news']" (click)="closeMenu()" routerLinkActive="active-link">NEWS</a></li> -->
                            <li><a [routerLink]="['/pricing']" class="device"
                                routerLinkActive="active-link">{{'Order your device' | translate}}</a></li>
                            <li><a href="https://app.clickreview.pro/login" class="log-in" [ngClass]="{'blue': isBlue}" 
                                    routerLinkActive="active-link">Login</a></li>
                                    <!-- <li class="languages-dropdown mobile" (click)="openMobLanguage()">
                                        <p>{{selectLang | uppercase}}</p>
                                        <img class="main-icon" src="assets/images/gr.svg" alt="" *ngIf="selectLang === 'gr'">
                                        <img class="main-icon" src="assets/images/en.svg" alt="" *ngIf="selectLang === 'en'">
                                        <img src="assets/images/black-arrow-down.svg" alt="" class="arrow">
                                        <ul class="dropdown-ul" *ngIf="mobLanguage">
                                            <li (click)="setTransLanguage('gr')">GR <img src="assets/images/gr.svg" alt=""></li>
                                            <li (click)="setTransLanguage('en')">EN <img src="assets/images/en.svg" alt=""></li>
                                        </ul>
                                    </li> -->
                        </ul>
                    </div>
                    <div class="buttons">
                        <button class="action" [routerLink]="['/pricing']" *ngIf="!ispricing">{{'Order your device' | translate}}</button>
                        <a class="log-in" [ngClass]="{'blue': !isBlue}" href="https://app.clickreview.pro/login">Log in</a>
                    </div>
                    <div class="cleaner"></div>
                </div>
            </div>
        </div>
    </div>
</div>