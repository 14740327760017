<!-- <div class="hero">
    <div class="container">
        <div class="hero-description">
            <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
            <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
            <div class="social-icons">
                <div class="icons">
                    <img src="assets/images/instagram.svg" alt="">
                    <img src="assets/images/facebook.svg" alt="">
                    <img src="assets/images/google.svg" alt="">
                    <img src="assets/images/website.svg" alt="">
                    <img src="assets/images/tripadvisor.svg" alt="">
                    <img src="assets/images/tik-tok.svg" alt="">
                    <img src="assets/images/appstore.svg" alt="">
                    <img src="assets/images/google-play.svg" alt="">
                    <img src="assets/images/folder.svg" alt="">
                </div>
                <div class="text">{{'and a lot of more' | translate}}</div>
            </div>
        </div>
    </div>
</div>
<div class="mobile-description">
    <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
    <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
    <div class="social-icons">
        <img src="assets/images/instagram.svg" alt="">
        <img src="assets/images/facebook.svg" alt="">
        <img src="assets/images/google.svg" alt="">
        <img src="assets/images/website.svg" alt="">
        <img src="assets/images/tripadvisor.svg" alt="">
    </div>
</div>
<div class="container">
    <div class="review-text">
        <h4>{{'Never Miss Another Review' | translate}}</h4>
        <p>{{'Get YOUR Clickreview Card Today!' | translate}}</p>
    </div>
    <div class="card-wrapper">
        <div class="card">
            <h5>{{'CONTACT FOR PRICING' | translate}}</h5>
            <p>{{'Start a conversation with our team to find a ClickReview package as unique as your business!' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Send request' | translate | uppercase}}</button>
            </div>
        </div>
        <div class="card">
            <h5>{{'Need to chat? Got a question?' | translate | uppercase}}</h5>
            <p>{{'Simply fill out the form to the right, and our team will get back to you as soon as possible – usually within one business day.' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Contact us' | translate | uppercase}}</button>
            </div>
        </div>
    </div>
</div>
<app-subscribe></app-subscribe>
-->


<div class="hero" (window:resize)="onResize($event)">

    <div class="hero-content">
        <h5 class="top">{{'ABOUT US' | translate}}</h5>
        <h1>{{'Get to know us' | translate}}</h1>
        <p>{{'At ClickReview, we’re passionate about helping businesses of all sizes improve their online reputation.
            Our review management system is designed to make it easy for your customers to leave reviews on the
            platforms that matter most, and for you to monitor and manage your online reputation in real-time. Learn
            more about who we are and what we do below.' | translate}}</p>
        <div class="button-wrapper">
            <button [routerLink]="['/pricing']">{{'Get your device' | translate}}</button>
        </div>
    </div>
    <!-- <div class="container"> -->
    <!-- <div class="bg-overlay"></div>
        <video [muted]="true" autoplay loop playsinline>
            <source src="assets/images/hero-new.mp4" type="video/mp4">
        </video>
        <div class="hero-content">
            <h1>{{'RAISE YOUR ONLINE RANKING TO THE NEXT LEVEL WITH CLICK REVIEW' | translate}}</h1>
            <p>{{'Click review’s unique solution allows you to improve your online reputation and visibility.' | translate}}</p>
            <button [routerLink]="['/contact']">{{'Order now' | translate}}</button>
        </div> -->
    <!-- <div class="hero-description">
            <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
            <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
                loyal social following – all in under a minute!</p>
            <button>GET MY CLICKREVIEW CARD</button>
        </div> -->
    <!-- </div> -->
</div>
<div class="main-wrapper">
    <div class="container">
        <div class="text-sides">
            <div class="text-side">
                <p class="uptitle">{{'MISSION' | translate}}</p>
                <h2>{{'Our Mission' | translate}}</h2>
                <p class="text">
                    {{'At ClickReview, our mission is to help businesses build and maintain a strong online reputation. We
                    believe that positive online reviews are critical to the success of any business, and we’re
                    committed to making it easy for our clients to collect, manage, and respond to reviews on all of the
                    top platforms. Our goal is to provide a user-friendly review management system that helps our
                    clients increase revenue, build customer trust, and stand out in a crowded market. We’re passionate
                    about what we do, and we’re always striving to innovate and improve our system to better serve our
                    clients.' | translate}}
                </p>
            </div>
            <div class="block-side">
                <img src="assets/images/blocks.svg" alt="">
            </div>
        </div>
    </div>
</div>
<div class="cards-con-wrapper">
    <div class="container">
        <div class="cards-con">
            <p class="uptitle">{{'CORE VALUES' | translate}}</p>
            <h2>{{'Our goals' | translate}}</h2>
            <div class="cards-wrapper-con">
                <div class="cards first">
                    <div class="card green">
                        <h6>01</h6>
                        <h3>{{'Establish a positive image for your brand' | translate}}</h3>
                        <p>{{'ClickReview will help you boost your average rating on the world’s most used review
                            platforms, from Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                    <div class="card white" *ngIf="isMobile">
                        <h6>02</h6>
                        <h3>{{'Boost your average rating.' | translate}}</h3>
                        <p>{{'We will help you boost your average rating on the world’s most used review platforms, from
                            Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                    <div class="card green" *ngIf="isMobile">
                        <h6>03</h6>
                        <h3>{{'Measure your customer satisfaction.' | translate}}</h3>
                        <p>{{'ClickReview will help you boost your average rating on the world’s most used review
                            platforms, from Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                    <div class="card white">
                        <h6>04</h6>
                        <h3>{{'Boost your visibility.' | translate}}</h3>
                        <p>{{'ClickReview will help you boost your average rating on the world’s most used review
                            platforms, from Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                    <div class="card blue" *ngIf="isMobile">
                        <h6>05</h6>
                        <h3>{{'Grow your following on social media' | translate}}</h3>
                        <p>{{'We will help you boost your average rating on the world’s most used review platforms, from
                            Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                    <div class="card white" *ngIf="isMobile">
                        <h6>06</h6>
                        <h3>{{'Soar up search results' | translate}}</h3>
                        <p>{{'ClickReview will help you boost your average rating on the world’s most used review
                            platforms, from Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                </div>
                <div class="cards" *ngIf="!isMobile">
                    <div class="card white">
                        <h6>02</h6>
                        <h3>{{'Boost your average rating.' | translate}}</h3>
                        <p>{{'We will help you boost your average rating on the world’s most used review platforms, from
                            Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                    <div class="card blue">
                        <h6>05</h6>
                        <h3>{{'Grow your following on social media' | translate}}</h3>
                        <p>{{'We will help you boost your average rating on the world’s most used review platforms, from
                            Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                </div>
                <div class="cards third" *ngIf="!isMobile">
                    <div class="card green">
                        <h6>03</h6>
                        <h3>{{'Measure your customer satisfaction.' | translate}}</h3>
                        <p>{{'ClickReview will help you boost your average rating on the world’s most used review
                            platforms, from Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                    <div class="card white">
                        <h6>06</h6>
                        <h3>{{'Soar up search results' | translate}}</h3>
                        <p>{{'ClickReview will help you boost your average rating on the world’s most used review
                            platforms, from Google to Facebook, TripAdvisor, and beyond.' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container green-con">
    <h2>{{'Let’s talk numbers' | translate}}</h2>
    <div class="green-wrapper">
        <div class="sections">
            <div class="section">
                <p class="title">2'500</p>
                <p class="text">{{"ClickReview has helped over 2'500 businesses improve their online reputation." | translate}}
                </p>
            </div>
            <div class="section">
                <p class="title">50'000</p>
                <p class="text">{{'Our system has collected over 50,000 reviews for our clients.' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">23</p>
                <p class="text">{{'We have a team of 23 employees dedicated to providing exceptional customer service.' | translate}}</p>
            </div>
        </div>
        <div class="sections last">
            <div class="section">
                <p class="title">8%</p>
                <p class="text">{{'Our clients have seen an average increase in revenue of 8% since using our system.' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">15%</p>
                <p class="text">{{'Our clients have seen an average increase in website traffic of 15% since using our system.' | translate}}</p>
            </div>
            <div class="section">
                <p class="title">1.5</p>
                <p class="text">{{'Our system has increased the average rating for our clients by 1.5 stars on popular review platforms.' | translate}}</p>
            </div>
        </div>
    </div>
</div>

<div class="section-container">
    <div class="container">
        <div class="section-text">
            <div class="left"><h3>{{'Why Choose 
                ClickReview?' | translate}}</h3></div>
                <div class="right"><p>{{'At ClickReview, we’re committed to helping businesses build and maintain a positive online reputation. Our user-friendly review management system, dedicated team, and proven results make us the top choice for businesses of all sizes. Contact us today to learn more about how ClickReview can benefit your business.' | translate}}</p></div>
        </div>
    </div>
</div>
<app-subscribe></app-subscribe>