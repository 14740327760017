<div class="hero">
    
    <div class="hero-content">
        <h5 class="top">{{'PRICING PLANS' | translate}}</h5>
        <h1>{{'Choose the Right Plan for Your Business' | translate}}</h1>
        <p>{{'Our flexible pricing plans are designed to meet the needs of businesses of all sizes. Whether you’re a small business looking to improve your online reputation, or a larger organization with multiple locations, we have a plan that will work for you.' | translate}}</p>
    </div>
    <!-- <div class="container"> -->
    <!-- <div class="bg-overlay"></div>
        <video [muted]="true" autoplay loop playsinline>
            <source src="assets/images/hero-new.mp4" type="video/mp4">
        </video>
        <div class="hero-content">
            <h1>{{'RAISE YOUR ONLINE RANKING TO THE NEXT LEVEL WITH CLICK REVIEW' | translate}}</h1>
            <p>{{'Click review’s unique solution allows you to improve your online reputation and visibility.' | translate}}</p>
            <button [routerLink]="['/contact']">{{'Order now' | translate}}</button>
        </div> -->
        <!-- <div class="hero-description">
            <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
            <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
                loyal social following – all in under a minute!</p>
            <button>GET MY CLICKREVIEW CARD</button>
        </div> -->
    <!-- </div> -->
</div>
<div class="main-wrapper">
    <div class="container">
        <div class="pricing">
            <div class="price blue">
                <img src="assets/images/pro.svg" alt="">
                <div class="price-num" *ngIf="blueFirst">
                    <p class="up">{{'$88/mo' | translate}}</p>
                    <p class="down" style="visibility: hidden;">{{'billed at' | translate}} <span>840$</span> {{'765$/yr' | translate}}</p>
                </div>
                <div class="price-num" *ngIf="!blueFirst">
                    <p class="up">{{'950$/year' | translate}}</p>
                    <p class="down" style="visibility: hidden;">{{'billed at' | translate}} <span>840$</span> {{'765$/yr' | translate}}</p>
                </div>
                <div class="slide-wrapper" [ngClass]="{'first': blueFirst}" (click)="switchBlue()">
                    <div class="left">
                        <p class="ups">{{'Pay monthly' | translate}}</p>
                        <p class="downs">{{'Commit monthly' | translate}}</p>
                    </div>
                    <div class="left">
                        <p class="ups sec" [ngClass]="{'es': lang == 'es' || lang == 'de' || lang == 'fr'}">{{'Pay Uprfont SAVE 10%' | translate}}</p>
                        <p class="downs sec">{{'Commit annually' | translate}}</p>
                    </div>
                    <div class="half" (click)="onEvent($event)"></div>
                </div>
                <div class="desc">
                    <p class="small">{{'Best suited for small organizations' | translate}}</p>
                    <p class="big">{{'Includes' | translate}}:</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> {{'Yearly licence for using ClickReview' | translate}}</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> {{'One ClickReview Device' | translate}}</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> {{'One ClickReview Stand Device' | translate}}</p>
                </div>
                <div class="buttons-wrapper">
                    <button [routerLink]="['/contact']">
                        {{'I want professional' | translate}}
                    </button>
                </div>
            </div>
            <!-- <div class="price green">
                <img src="assets/images/ent.svg" alt="">
                <div class="price-num" *ngIf="greenFirst">
                    <p class="up">$80/mo</p>
                    <p class="down" style="visibility: hidden;">billed at <span>840$</span> 765$/yr</p>
                </div>
                <div class="price-num" *ngIf="!greenFirst">
                    <p class="up">$80/mo</p>
                    <p class="down">billed at <span>960$</span> 864$</p>
                </div>
                <div class="slide-wrapper" [ngClass]="{'first': greenFirst}" (click)="switchGreen()">
                    <div class="left">
                        <p class="ups">Pay monthly</p>
                        <p class="downs">Commit monthly</p>
                    </div>
                    <div class="left">
                        <p class="ups sec">Pay Uprfont SAVE 10%</p>
                        <p class="downs sec">Commit annually</p>
                    </div>
                    <div class="half" (click)="onEvent($event)"></div>
                </div>
                <div class="desc">
                    <p class="small">Best suited for bigger organizations</p>
                    <p class="big">Includes:</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> ClickReview Stand Device</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> One ClickReview Device</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> ClickReview Mobile Devices</p>
                </div>
                <div class="buttons-wrapper">
                    <button [routerLink]="['/contact']">
                        I want enterprise
                    </button>
                </div>
            </div> -->
            <div class="price green">
                <img src="assets/images/cus.svg" alt="">
                <div class="price-num">
                    <p class="up" [ngClass]="{'fr': lang == 'fr'}">{{'from $100/mo' | translate}}</p>
                    <p class="down">{{'starts from $1.200/yr' | translate}}</p>
                </div>
                <div class="desc">
                    <p class="small">{{'Best suited for large enterprises' | translate}}</p>
                    <p class="big">{{'Includes' | translate}}:</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> {{'Yearly licence for using ClickReview' | translate}}</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> {{'Individual number of ClickReview Stand Devices' | translate}}</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> {{'Individual number of ClickReview Mobile Devices' | translate}}</p>
                    <p class="big"><img src="assets/images/yes.svg" alt=""> {{'24/7 Support' | translate}}</p>
                </div>
                <div class="buttons-wrapper">
                    <button [routerLink]="['/contact']">
                        {{'Contact us' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="listing">
            <h4>{{'Transparent Pricing Guarantee' | translate}}</h4>
            <div class="texts">
                <div class="text">
                    <div class="icon-wrapper">
                        <img src="assets/images/blue-okay.svg" alt="">
                    </div>
                    <p>{{'Transparent Pricing: No hidden fees or charges.' | translate}}</p>
                </div>
                <div class="text">
                    <div class="icon-wrapper">
                        <img src="assets/images/blue-okay.svg" alt="">
                    </div>
                    <p>{{'Flexible Plans: Tailored to your specific needs.' | translate}}</p>
                </div>
                <div class="text">
                    <div class="icon-wrapper">
                        <img src="assets/images/blue-okay.svg" alt="">
                    </div>
                    <p>{{'Price Lock Guarantee: The price you sign up for is the price you’ll pay.' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container">
        <div class="intro-wrapper">
            <h2 [innerHTML]="'How Does ClickReview Work?' | translate"></h2>
            <div class="image-wrapper">
                <img src="assets/images/playy.svg" alt="" class="play-img" (click)="playVid()">
                <video controls *ngIf="lang === 'de'">
                    <source src="assets/images/how.mp4" type="video/mp4">
                </video>
                <video controls>
                    <source src="assets/images/how-en.mp4" type="video/mp4">
                </video>
                <img src="assets/images/video.png" alt="" class="main-img">
                
            </div>
        </div>
        <div class="crp-wrapper">
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW BOARD' | translate}}</h4>
                <h6 [innerHTML]="'No Installation. No Hassle!' | translate"></h6>
                <p>{{'All your customers need to do is bring their smartphone near the CLICKREVIEW board, and they’ll instantly be taken to your landing page to leave a great review in moments!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW PAGE' | translate}}</h4>
                <h6 [innerHTML]="'Online Platforms Your Customers Love!' | translate"></h6>
                <p>{{'We empower your customers to have their say on the world’s most popular online review platforms, all at the touch of a button. They can even follow you on the socials in a click!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper laptop">
                    <img src="assets/images/laptop.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW ADMIN PANEL' | translate}}</h4>
                <h6 [innerHTML]="'Monitor Your Progress and Collect Feedbacks!' | translate"></h6>
                <p>{{'Easily set your landing page up with accompanying CLICKREVIEW boards, track stats, and unlock feedback on your service quality in real-time!' | translate}}</p>
            </div>
        </div>
        <div class="options-wrapper">
            <div class="left-side">
                <h3 [innerHTML]="'Why Choose ClickReview Pro?' | translate"></h3>
                <ul>
                    <li>{{'Boost your average rating on the world’s most used review platforms, from Google to Facebook, TripAdvisor, and beyond' | translate}}</li>
                    <li>{{'Analyze consumer interest in existing and new products to determine your upsell potential' | translate}}</li>
                    <li>{{'Easily grow your following on social media without stretching your budget' | translate}}</li>
                    <li>{{'Effortlessly soar up search results' | translate}}</li>
                    <li>{{'Establish a positive image for your brand' | translate}}</li>
                    <li>{{'Earn more visibility and keep existing customers while winning new ones' | translate}}</li>
                    <li>{{'Measure customer satisfaction using specific segmentation' | translate}}</li>
                    <li>{{'Unlock your employee evaluation score to view work quality at a glance' | translate}}</li>
                    <li>{{'Get your Net Promoter Score (NPS®) to predict growth and measure customer experiences' | translate}}</li>
                </ul>
            </div>
            <div class="right-side">
                <img src="assets/images/phone-1.png" alt="" class="phone">
                <img src="assets/images/facebook.svg" class="icon facebook" alt="">
                <img src="assets/images/website.svg" class="icon website" alt="">
                <img src="assets/images/tripadvisor.svg" class="icon trip" alt="">
                <img src="assets/images/instagram.svg" class="icon instagram" alt="">
            </div>
        </div>
        <div class="button-wrapper">
            <button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button>
        </div>
        <div class="image-back">
            <img src="assets/images/clients-back.png" alt="">
            <div class="text-back">
                <h3 [innerHTML]="'Did you know that, on average, ClickReview clients get a staggering 500% MORE reviews?' | translate"></h3>
                <p>{{'With CLICKREVIEW, you can start bringing in countless real, authentic reviews and foster trust and faith in your business – all in a matter of seconds!' | translate}}</p>
            </div>
        </div>
        <div class="columns-wrapper">
            <div class="column">
                <h4 [innerHTML]="'GROW YOUR SOCIAL FOLLOWING IN NO TIME' | translate"></h4>
                <p>{{'CLICKREVIEW makes it easier than ever to for your customers to leave you great reviews online, so you can build a bigger, more loyal audience and send your follower count soaring.' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'INSPIRE YOUR TEAM TO IMPROVE' | translate"></h4>
                <p>{{'Seeing as each CLICKREVIEW board carries its own unique serial number, you can effortlessly track the number of reviews you get from each one. What better way to get your staff inspired to boost their productivity and deliver better quality of service? Customer satisfaction will soar, all thanks to CLICKREVIEW and you!' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'REAL-TIME CUSTOMER FEEDBACK' | translate"></h4>
                <p>{{'Consumers always want to see that businesses have a constant influx of positive reviews, meaning that real-time feedback is the key to loyal business-customer relationships. By peeking into your customers’ minds, you’ll also be able to dramatically improve your business based on their feedback.' | translate}}</p>
            </div>
        </div>
    </div> -->
    <!-- <app-subscribe></app-subscribe> -->

    <!-- <div class="container">
        <div class="references-wrapper">
            <img src="assets/images/st.regis.svg" alt="">
            <img src="assets/images/hilton.svg" alt="">
            <img src="assets/images/hyat.svg" alt="">
            <img src="assets/images/marrlott.svg" alt="">
            <img src="assets/images/Four_Seasons_logo Copy 3.svg" alt="">
        </div>
    </div> -->
</div>

<div class="faq-wrapper">
    <div class="container">
        
        <div class="slider">
            <div class="card">
                <img src="assets/images/apos.svg" alt="" class="apos">
                <p class="desc">{{'I was hesitant to invest in yet another software for my business, but ClickReview has been worth every penny. Their pricing is transparent, and I appreciate the flexibility to choose a plan that fits my business’s unique needs. Our online reputation has improved significantly, and I’m excited to see what the future holds.' | translate}}</p>
                <p class="name">Missy J</p>
            </div>
        </div>
        <div class="pag">
            <span class="active"></span>
            <span></span>
            <span></span>
            <span></span>
        </div>



        <div class="faq">
            <h5 class="top">{{'FAQ' | translate}}</h5>
            <h3>{{'You’ve got questions. We’ve got answers.' | translate}}</h3>
            <div class="tabordion">
                <section id="section1" #sectionOne>
                    <label id="option1" (click)="showtab('one')"><span [ngClass]="{rotate : firstTabState=='open'}">+</span>
                        <p>{{'How much does your review management system cost?' | translate}} </p>
                    </label>
                    <div class="article-wrapper" [@openClose]=firstTabState>
                        <article>
                            <p>{{'Our pricing varies depending on the plan you choose and the number of devices you need. We offer flexible plans that can be tailored to your business’s specific needs. Contact us for more information and a customized quote.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section2" #sectionTwo>
                    <label id="option2" (click)="showtab('two')"><span
                            [ngClass]="{rotate : secondTabState=='open'}">+</span>
                        <p>{{'Are there any hidden fees or charges?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=secondTabState>
                        <article>
                            <p>{{'No, we believe in transparent pricing, so there are no hidden fees or charges. What you see is what you get with our pricing plans.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section3" #sectionThree>
                    <label id="option3" (click)="showtab('three')"><span
                            [ngClass]="{rotate : thirdTabState=='open'}">+</span>
                        <p>{{'Can I change my plan or cancel at any time?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=thirdTabState>
                        <article>
                            <p>{{'Yes, you can change your plan or cancel at any time. We offer flexible plans that can be tailored to your changing needs.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section4" #sectionFour>
                    <label id="option4" (click)="showtab('four')"><span
                            [ngClass]="{rotate : fourthTabState=='open'}">+</span>
                        <p>{{'Is there a setup fee?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=fourthTabState>
                        <article>
                            <p>{{'No, we do not charge a setup fee for our review management system.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section5" #section5>
                    <label id="option5" (click)="showtab('five')"><span
                            [ngClass]="{rotate : five=='open'}">+</span>
                        <p>{{'How does your pricing compare to other review management systems?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=five>
                        <article>
                            <p>{{'Our pricing is competitive and affordable, and we offer flexible plans that can be tailored to your business’s specific needs. Contact us for a customized quote and to learn more about how we compare to other review management systems.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section6" #section6>
                    <label id="option6" (click)="showtab('six')"><span
                            [ngClass]="{rotate : six=='open'}">+</span>
                        <p>{{'Do you offer any discounts for annual contracts?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=six>
                        <article>
                            <p>{{'Yes, we offer discounts for annual contracts. Contact us for more information and a customized quote.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section7" #section7>
                    <label id="option7" (click)="showtab('seven')"><span
                            [ngClass]="{rotate : seven=='open'}">+</span>
                        <p>{{'Do you charge for additional features or services?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=seven>
                        <article>
                            <p>{{'We offer a range of features and services as part of our pricing plans, but if you require additional services or customizations, there may be additional charges. Contact us for more information.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section8" #section8>
                    <label id="option8" (click)="showtab('eight')"><span
                            [ngClass]="{rotate : eight=='open'}">+</span>
                        <p>{{'What happens if I exceed the number of devices in my plan?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=eight>
                        <article>
                            <p>{{'If you exceed the number of devices in your plan, you may need to upgrade to a higher plan. Contact us for more information and a customized quote.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section9" #section9>
                    <label id="option9" (click)="showtab('nine')"><span
                            [ngClass]="{rotate : nine=='open'}">+</span>
                        <p>{{'What payment methods do you accept?' | translate}}</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=nine>
                        <article>
                            <p>{{'We accept a variety of payment methods, including credit card, PayPal, and bank transfer. Contact us for more information on payment options.' | translate}}
                            </p>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<app-subscribe></app-subscribe>